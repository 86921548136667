import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Container } from "react-bootstrap";

const PaginationB = ({ page, setPage, totalCount }) => {
  const [pageCount, setPageCount] = useState([2]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const totalPageMinusOne = pageCount[0] - 1;
  const hideLast = totalPageMinusOne == page;
  useEffect(() => {
    setPageCount(totalCount * 1);
  }, [totalCount]);

  return (
    <div className="mt-4">
      <Container>
        <Stack>
          <div className="d-flex " style={{ justifyContent: "flex-end" }}>
            <Pagination
              className={
                page == 1
                  ? "hide_ellipses hide_second"
                  : page == pageCount.length
                  ? "hide_ellipses hide_second"
                  : "hide_ellipses sss"
              }
              count={pageCount}
              page={page}
              onChange={handleChange}
              siblingCount={page == 1 ? -1 : 0}
              boundaryCount={page == 2 ? -1 : 0}
              // siblingCount={  0}
              // boundaryCount={ -1}
              //on 1  sib -1 bc 0

              // on 10 sib -1 bc 0
            />

            <div className="d-flex">
              <Typography>Page</Typography>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handleChange}
                siblingCount={0}
                hideNextButton={true}
                hidePrevButton={true}
              />
            </div>
          </div>
        </Stack>
      </Container>
    </div>
  );
};

export default PaginationB;
