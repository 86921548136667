import React, { useState, useEffect } from "react";
import AdminSideBar from "../components/AdminSideBar";
import { Container, Row, Col } from "react-bootstrap";
import { Get, Post } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL } from "../Config/apiUrl";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BodyLoader from "../components/BodyLoader";
import ReactQuill from "react-quill";

function Support_cms() {
  const location = useLocation();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const {
    state: { pageName, pageId },
  } = location;
  const accessToken = useSelector((state) => state.authReducer.access_token);

  //  section1 states

  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");

  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName: pageName,

      description1: description1,
      description2: description2,
    };

    const formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }

    //  post Api Order Page

    const url = BaseURL("cms/page/update");
    setIsloading(true);
    const response = await Post(url, formData, apiHeader(accessToken));
    setIsloading(false);

    if (response !== undefined) {
      toast.success("Support Page data updated successfully");
    }
  };

  // Get api Order page
  const getData = async () => {
    const url = BaseURL(`cms/page/${pageName}`);
    setIsApiCall(true);
    const response = await Get(url, apiHeader(accessToken));
    setIsApiCall(false);
    if (response !== undefined) {
      let data = response?.data?.data;

      setDescription1(data?.description1);
      setDescription2(data?.description2);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="add-project-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <h3>
                    {pageName == "terms_and_condition"
                      ? "Terms and Condition"
                      : pageName == "privacy_policy"
                      ? "Privacy Policy"
                      : pageName == "privacy_policy_2"
                      ? "Privacy Policy 2.0"
                      : "Cms"}{" "}
                    Page :
                  </h3>
                </Col>
                <Col lg={12}>
                  <Row>
                    {/* Hero Section */}

                    <Col lg={12}>
                      <h4>Section1:</h4>
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description1:</h5>

                      <ReactQuill
                        value={description1}
                        onChange={(e) => setDescription1(e)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description2:</h5>
                      <ReactQuill
                        value={description2}
                        onChange={(e) => setDescription2(e)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <div className="save-btn">
                    <button
                      className="save-btn"
                      disabled={isApiCall}
                      onClick={() => handleSubmit()}
                    >
                      {isloading ? "please wait..." : "Save"}
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {isApiCall && <BodyLoader />}
    </>
  );
}

export default Support_cms;
