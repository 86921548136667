import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ModalSkeleton from "./ModalSkeleton";

function BankingDetailModal({ open, setOpen, title, handleYes }) {
  return (
    <ModalSkeleton open={open} setOpen={setOpen} maxContent>
      <section className="are-you-sure-modal">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="modal-box">
                <h5>Banking Details</h5>
                <p
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {title}
                </p>
                <div className="btns-box">
                  <button className="yes-btn" onClick={handleYes}>
                    Okay
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </ModalSkeleton>
  );
}

export default BankingDetailModal;
