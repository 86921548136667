import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { toast } from "react-toastify";
import { apiHeader, BaseURL, validateEmail } from "../Config/apiUrl";
import { Post } from "../Axios/AxiosFunctions";

export default function ForgotPasswordModal({ showModal, closeModal }) {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // handleSubmit
  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      toast.error(`Email is not valid`);
      return;
    }

    const url = BaseURL("users/forgotPassword");
    setIsLoading(true);
    const response = await Post(url, { email }, apiHeader());
    setIsLoading(false);

    if (response !== undefined) {
      closeModal();
      setEmail("");
      toast.success("Password reset link sent to your email");
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          closeModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Forgot Password?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label
                style={{
                  margin: "10px 60px",
                }}
              >
                Enter your email, we will send you a password link to reset your
                password.
              </Form.Label>
              <Form.Control
                className="input-box siginInputbox"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete={false}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-x">
            <button
              className="btn-detail"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "Please Wait..." : `Send Reset Link`}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
