import React, { useState, useEffect } from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import {  useSelector } from "react-redux";
import {
  BaseURL,
  formRegExReplacer,
  recordsLimit,
  formRegEx,
  numberWithCommas,
} from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import AdminSideBar from "../components/AdminSideBar";
import { currencies } from "../currencies";
import BodyLoader from "../components/BodyLoader";
import { useHistory } from "react-router-dom";
import PaginationB from "../components/PaginationB";
import NullDataComponent from "./../components/NullDataComponent";


export default function AssignProjects() {
  const history = useHistory();
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [assignedProjects, setAssignedProjects] = useState([]);
  const [isApicall, setIsApicall] = useState(false);
  const [status, setStatus] = useState("all");
  const newArray = assignedProjects.filter(function (item) {
    if (status === "all") {
      return item;
    } else {
      return item?.projectStatus === status;
    }
  });

  // get Data
  async function getData(_page) {
    const url = BaseURL(
      `admin/assignProject?limit=${recordsLimit}&page=${_page}`
    );
    setIsApicall(true);
    const response = await Get(url, accessToken);
    setIsApicall(false);
    if (response !== undefined) {
      setAssignedProjects(response?.data.data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
  }

  useEffect(() => {
    getData(page);
  }, [page]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <div className="Dashboardpage-invoice mt-0">
                    <h2>Assigned Projects</h2>
                    <select
                      className="dropdown-style-x"
                      onChange={(e) => setStatus(e?.target?.value)}
                      value={status}
                    >
                      {[
                        "all",
                        "pending",
                        "underReview",
                        "inProgress",
                        "completed",
                      ].map((e) => (
                        <option key={e} value={e}>
                          {e.replace(formRegEx, formRegExReplacer)}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                {assignedProjects.length > 0 && (
                  <Col lg={12}>
                    <div className="invoice-Table">
                      <table>
                        <thead className="invoice-head">
                          <tr>
                            <th>No.</th>
                            <th>Project Title</th>
                            <th>Budget</th>
                            <th>Status</th>
                            <th>Funds Released</th>
                            <th>Project Completion</th>
                            <th>Days Left</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newArray.map((item, index) => {
                            // cus
                            let progressClass = "danger";
                            let progressValue =
                              (item.amountPayedToAdmin / item.amount) * 100;
                            if (progressValue <= 10) progressClass = "danger";
                            else if (progressValue > 10 && progressValue <= 30)
                              progressClass = "warning";
                            else if (progressValue > 30 && progressValue <= 80)
                              progressClass = "primary";
                            else if (progressValue > 80 && progressValue >= 100)
                              progressClass = "success";
                            else progressClass = "danger";

                            // free
                            let progressClassF = "danger";
                            let progressValueF =
                              (item.amountPayedToFreelancer / item.amount) *
                              100;
                            if (progressValueF <= 10) progressClassF = "danger";
                            else if (
                              progressValueF > 10 &&
                              progressValueF <= 30
                            )
                              progressClassF = "warning";
                            else if (
                              progressValueF > 30 &&
                              progressValueF <= 80
                            )
                              progressClassF = "primary";
                            else if (
                              progressValueF > 80 &&
                              progressValueF >= 100
                            )
                              progressClassF = "success";
                            else progressClassF = "danger";

                            return (
                              <tr
                                className="invoice-row projectsection c-p"
                                key={item?._id}
                                onClick={() =>
                                  history.push({
                                    pathname: "/admin/detailPage",
                                    state: item._id,
                                    search: 'loc=1'
                                  })
                                }
                              >
                                <td>{index + 1}</td>
                                <td>{item.title}</td>
                                <td>
                                  {
                                    currencies[item.currency.toUpperCase()]
                                      .symbol
                                  }
                                  {/* {numeral(item?.amount).format("0,00a")} */}
                                  {numberWithCommas(item?.amount)}
                                </td>
                                <td className={`t-t-c ${item.projectStatus}`}>
                                  {item.projectStatus.replace(
                                    formRegEx,
                                    formRegExReplacer
                                  )}
                                </td>

                                {/* Cus */}
                                <td>
                                  <div className="innerspan">
                                    <span className="value-box">
                                      {parseFloat(
                                        progressValue >= 100
                                          ? 100
                                          : progressValue
                                      ).toFixed(0)}
                                      %
                                    </span>
                                    <ProgressBar
                                      variant={progressClass}
                                      now={progressValue}
                                      className={progressClass}
                                    />
                                  </div>
                                </td>

                                {/* fre */}
                                <td>
                                  <div className="innerspan">
                                    <span className="value-box">
                                      {parseFloat(
                                        progressValueF >= 100
                                          ? 100
                                          : progressValueF
                                      ).toFixed(0)}
                                      %
                                    </span>
                                    <ProgressBar
                                      variant={progressClassF}
                                      now={progressValueF}
                                      className={progressClassF}
                                    />
                                  </div>
                                </td>
                                {/* <td>{item?.noOfDays ? `${item?.noOfDays}` : '-'}</td> */}
                                <td>
                                  {item?.noOfDays && progressValueF >= 100
                                    ? "-"
                                    : item?.noOfDays
                                    ? `${item?.noOfDays}`
                                    : "-"}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      {totalCount > 1 && (
                        <PaginationB
                          page={page}
                          setPage={setPage}
                          totalCount={totalCount}
                        />
                      )}
                    </div>
                  </Col>
                )}

                {newArray.length === 0 && !isApicall && (
                  <div className="p-d-null-data">
                    <NullDataComponent c4 />
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {isApicall && <BodyLoader />}
    </>
  );
}
