import React from 'react'
import AdminSideBar from "../components/AdminSideBar";
import {Container, Row, Col } from "react-bootstrap";
import PDFIcon from '../assets/images/pdf.svg'



const WithdrawalRequest = () => {
  return (
    <>
    <section className='withdrwalRequest_container'>
        <Container>
            <Row>
                <Col lg={3}>
              <AdminSideBar />
                </Col>
                <Col lg={9}>
                  <Row className="projectTitle">
                    <Col md={12}>
                      <h2>Project Title</h2>
                    </Col>
                    <Col md={12}>
                     <div className='withdrawl_Title'>
                     <ul>
                        <li><p className='mid'>Amount:     <span>5000$</span></p></li>
                        <li><p className='mid'>Status:     <span>5000$</span></p></li>
                        <li><p className='mid'>Download PDF:  <span><img src={PDFIcon} alt="pdf" /></span></p></li>
                      </ul>
                     </div>
                    </Col>
                    <Col md={12}>
                     <div className='projectTittle_description'>
                       <p className="small">
                       Description:
                       </p>
                       <p className="small">
                       Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                       </p>
                    
                     </div>
                    </Col>
                  </Row>
                  <Row className="projectTitle">
                    <Col md={12}>
                      <h2>Proposals</h2>
                    </Col>
                    <Col md={12}>
                     <div className='withdrawl_Title'>
                     <ul>
                        <li><p className='mid'>Title:     <span>5000$</span></p></li>
                        <li><p className='mid'>Amount:<span>Lorem Ipsum Is Simply Dummy Text     </span></p></li>
                      </ul>
                     </div>
                    </Col>
                    <Col md={12}>
                     <div className='projectTittle_description'>
                       <p className="small">
                       Description:
                       </p>
                       <p className="small">
                       Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                       </p>
                    
                     </div>
                    </Col>
                    <Col md={12}>
                      <div className="Withdrawl_btns">
                        <a href="#" className='btn accept'>Accept</a>
                        <a href="#" className='btn reject'>Reject</a>
                      </div>
                    </Col>
                  </Row>
                </Col>
            </Row>
        </Container>
    </section>
    
    
    </>
  )
}

export default WithdrawalRequest