import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { FaFilePdf } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  BaseURL,
  numberWithCommas,
  pdfUrl,
  recordsLimit,
} from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import AdminSideBar from "../components/AdminSideBar";
import { currencies } from "../currencies";
import PaginationB from "../components/PaginationB";
import BodyLoader from "../components/BodyLoader";
import { useHistory } from "react-router-dom";
import NullDataComponent from "./../components/NullDataComponent";
import ShowMoreShowLessText from "../components/ShowMoreShowLessText";

export default function Payments() {
  const history = useHistory();
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  const [Payments, setPayments] = useState([]);
  const [isApiCall, setIsApiCall] = useState(false);

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  async function getData(_page) {
    const url = BaseURL(`admin/Payments?limit=${recordsLimit}&page=${_page}`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      setPayments(response?.data.data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
  }

  useEffect(() => {
    getData(page);
  }, [page]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <div className="Dashboardpage-invoice mt-0">
                    <h2>Payments - Incoming</h2>
                  </div>
                </Col>
                {Payments?.length > 0 && (
                  <Col lg={12}>
                    <div className="invoice-Table table-responsive text-nowrap">
                      <table>
                        <thead className="invoice-head">
                          <tr>
                            <th>No.</th>
                            <th>Client Name</th>
                            <th>Project Title</th>
                            <th>Milestone Title</th>
                            <th>Amount</th>
                            <th>Payment Method</th>
                            <th>Payment Status</th>
                            <th>Status</th>
                            <th>User</th>
                            <th>Invoice</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Payments?.map((item, index) => {
                            let progressClass = "danger";
                            let progressValue =
                              (item?.projectId?.amountPayedToFreelancer /
                                item?.amount) *
                              100;

                            if (progressValue <= 10) progressClass = "danger";
                            else if (progressValue > 10 && progressValue <= 30)
                              progressClass = "warning";
                            else if (progressValue > 30 && progressValue <= 80)
                              progressClass = "primary";
                            else if (progressValue > 80 && progressValue <= 100)
                              progressClass = "success";
                            else progressClass = "danger";

                            return (
                              <tr
                                className="invoice-row projectsection c-p"
                                onClick={() =>
                                  history.push({
                                    pathname: "/admin/detailPage",
                                    state: item?.projectId?._id,
                                    search: "loc=3",
                                  })
                                }
                              >
                                <td>{index + 1}</td>
                                <td className="t-t-c">
                                  {item?.projectId?.postedBy?.name}
                                </td>
                                <td className="t-t-c">
                                  {
                                    <ShowMoreShowLessText
                                      text={item?.projectId?.title}
                                      visibility={15}
                                    />
                                  }
                                </td>
                                <td className="t-t-c">
                                  {item?.paymentMilestone?.title}
                                </td>
                                <td>
                                  {
                                    currencies[item?.currency.toUpperCase()]
                                      .symbol
                                  }
                                  {/* {numeral(item?.amount).format("0,00a")} */}
                                  {numberWithCommas(item?.amount)}
                                </td>
                                <td className="t-t-c">{item?.paymentMethod}</td>
                                <td
                                  className={`t-t-c ${item?.paymentMilestone?.status}`}
                                >
                                  {item?.paymentMilestone?.status}
                                </td>
                                <td className="t-t-c">
                                  {item?.isPaymentVerified
                                    ? "Verified"
                                    : "----"}
                                </td>
                                <td className="t-t-c">{item?.userId?.name}</td>

                                <td
                                  className="c-p"
                                  onClick={(e) => {
                                    window.open(
                                      `${pdfUrl}${item?.paymentMilestone?.invoice}`
                                    );
                                    {
                                      e.stopPropagation();
                                    }
                                  }}
                                >
                                  <span>Open PDF</span>
                                  <FaFilePdf className="close-icon" />
                                  <span></span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                )}
                {totalCount > 1 && (
                  <PaginationB
                    page={page}
                    setPage={setPage}
                    totalCount={totalCount}
                  />
                )}
              </Row>
              {Payments?.length === 0 && !isApiCall && (
                <div className="p-d-null-data">
                  <NullDataComponent c2 text={"No Payments yet."} />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {isApiCall && <BodyLoader />}
    </>
  );
}
