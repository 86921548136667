import React, { useState, useEffect } from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";

import line from "../assets/images/Path 1554.png";
import { FaFilePdf } from "react-icons/fa";
import Chart from "../components/Chart";
import { useSelector } from "react-redux";
import {
  formRegExReplacer,
  BaseURL,
  fallbackUser,
  formRegEx,
  imageUrl,
  pdfUrl,
  numberWithCommas,
} from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { currencies } from "../currencies";
import AdminSideBar from "../components/AdminSideBar";
import { useHistory } from "react-router-dom";
import BodyLoader from "../components/BodyLoader";
import moment from "moment";
import file_img from "../assets/images/file-img.png";
import NullDataComponent from "./../components/NullDataComponent";
import { AiOutlinePaperClip } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

export default function AdminDashboard() {
  const history = useHistory();
  const { access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  const [graphPeriod, setGraphPeriod] = useState("This Week");
  const [graphCurrency, setGraphCurrency] = useState("gbp");

  const [assignedProjects, setAssignedProjects] = useState([]);
  const [widthdrawlRequests, setWidthdrawlRequests] = useState([]);
  const [GraphData, setGraphData] = useState([]);
  const [queryDates, setQueryDates] = useState(null);
  const [WebsiteStats, setWebsiteStats] = useState([]);
  const [webStatsVariable, setWebStatsVariable] = useState([]);

  const [messageData, setMessageData] = useState([]);

  const [isApicall, setIsApiCall] = useState(false);

  // get data from api
  async function getData() {
    const url = BaseURL("admin/dashboardData");
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setAssignedProjects(data?.assignedProjects);
      setWidthdrawlRequests(data?.widthdrawlRequests);
      // setGraphData(data?.GraphData);
      setWebsiteStats(data?.WebsiteStats);
      setWebStatsVariable("Total");
      setMessageData(data?.msg);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  // scroll to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [isLoadingGraph, setISLoadingGraph] = useState(false);
  // graph ap[i]
  const graphApi = async (p, c, graphCurrency, gp) => {
    const url = BaseURL(
      `admin/getGraphData?pre=${p}&curr=${c}&currency=${graphCurrency}&type=${gp
        .toLowerCase()
        .replaceAll(" ", "-")}`
    );
    setISLoadingGraph(true);
    const response = await Get(url, accessToken);
    setISLoadingGraph(false);
    if (response !== undefined) {
      setGraphData(response?.data?.data?.GraphData);
      setQueryDates(response?.data?.data?.dates);
    }
  };

  useEffect(() => {
    let r1, r2;
    // if (graphPeriod === "This Week") {
    //   r1 = moment().format();
    //   r2 = moment().add(-6, "days").format();
    // } else {
    //   r1 = moment().add(-6, "days").format();
    //   r2 = moment().add(-13, "days").format();
    // }

    // r1 = moment().format();
    // r2 = moment().add(-30, "days").format();

    graphApi(new Date(r2), new Date(r1), graphCurrency, graphPeriod);
  }, [graphPeriod, graphCurrency]);

  console.log("graphPeriod ", graphPeriod)

  let getStatsKeys = Object.keys(WebsiteStats);

  // handle open correct url
  const handleOpenCorrectUrl = (src) => {
    let data = src;
    let w = window.open("about:blank");
    let image = new Image();
    image.src = data;
    setTimeout(function () {
      w.document.write(image.outerHTML);
    }, 0);
  };

  // validate Path
  const validatePath = (src, mediaType) => {
    if (
      src.startsWith("data:") &&
      ["png", "jpeg", "jpg", "gif"].includes(mediaType)
    ) {
      handleOpenCorrectUrl(src);
    } else {
      window.open(src, "_blank");
    }
  };

  // calculateImagePath
  const calculateImagePath = (src, mediaType) => {
    if (["png", "jpeg", "jpg", "gif"].includes(mediaType)) {
      return src;
    } else {
      return file_img;
    }
  };

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={7}>
                  <div className="budgetCard">
                    {/* <div className="question-icons">
                      <span>
                        <VscQuestion className="question-icon" />
                      </span>
                      <span>
                        <HiOutlineDotsHorizontal className="question-icon" />
                      </span>
                    </div> */}
                    <Row>
                      <Col lg={5}>
                        <div className="draft-links">
                          <ul>
                            {getStatsKeys.map((item, index) => {
                              return (
                                <li>
                                  <a
                                    href="#!"
                                    onClick={() => setWebStatsVariable(item)}
                                    className={
                                      item == webStatsVariable
                                        ? "active-draft-link"
                                        : "unactive-draft-link"
                                    }
                                  >
                                    <span>
                                      {item?.toLowerCase() == "Income from Projects"
                                        ? `Income from Projects`
                                        : `${item}`}
                                    </span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Col>

                      <Col lg={7}>
                        <div className="Budget-admin">
                          <span className="Budget-admin-img">
                            <img src={line} alt="line" />
                          </span>
                          {webStatsVariable != "Income from Projects" ? (
                            <>
                              <h1>{WebsiteStats[webStatsVariable]}</h1>
                            </>
                          ) : (
                            <>
                              <div>
                                {WebsiteStats[webStatsVariable].map(
                                  (item, index) => {
                                    return (
                                      <h3>
                                        {
                                          currencies[item._id.toUpperCase()]
                                            .symbol
                                        }
                                        {/* {numeral(item?.amount).format("0,00a")} */}
                                        {numberWithCommas(item?.amount)}
                                      </h3>
                                    );
                                  }
                                )}
                                <span className="xsmall earning-black">
                                  Total Earnings
                                </span>
                              </div>

                              {/* <p className="xsmall green">
                                +2 in the last 30 days
                              </p> */}
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="chart-card">
                    <div className="chart-heading">
                      <h3>Earnings</h3>
                      {/* currency */}
                      <select
                        className="graph-dropdown-x"
                        onChange={(e) => setGraphCurrency(e?.target?.value)}
                      >
                        <option value={"gbp"}>GBP</option>
                        <option value={"eur"}>EUR</option>
                      </select>
                      {/* Time */}
                      <select
                        className="graph-dropdown-x"
                        onChange={(e) => setGraphPeriod(e?.target?.value)}
                      >
                        <option value={"This Week"}>This Week</option>
                        <option value={"Last Week"}>Last Week</option>
                        <option value={"Last Month"}>Last Month</option>
                        <option value={"Last Three Months"}>
                          Last Three Months
                        </option>
                        <option value={"Last Six Months"}>
                          Last Six Months
                        </option>
                        <option value={"Last Twelve Months"}>
                          Last Twelve Months
                        </option>
                      </select>
                      {/* <RiArrowDropDownLine className="down-icon" /> */}
                    </div>
                    <Chart data={GraphData} graphPeriod={graphPeriod} queryDates={queryDates} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={7}>
                  {widthdrawlRequests?.length > 0 && (
                    <Row>
                      <Col lg={12}>
                        <div className="Dashboardpage-invoice">
                          <h2 className="payemets_consultants">
                            Payments to Consultants - Outgoing
                          </h2>
                          <div className="Dashboardpage-invoice-btn">
                            <button
                              onClick={() => history.push("/admin/withdraw")}
                            >
                              View More
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="invoice-Table adminTablee">
                          <table>
                            <thead className="invoice-head">
                              <tr>
                                {/* <th>No.</th> */}
                                <th>Project Title</th>
                                <th>Milestone Title</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Invoice</th>
                                <th>Invoice Password</th>
                              </tr>
                            </thead>
                            <tbody>
                              {widthdrawlRequests?.map((item, index) => {
                                return (
                                  <tr
                                    className="invoice-row c-p"
                                    key={index}
                                    onClick={() =>
                                      history.push({
                                        pathname: "/admin/detailPage",
                                        state: item?.projectDetail?.projectId,
                                      })
                                    }
                                  >
                                    {/* <td>{index + 1}</td> */}
                                    <td className="t-t-c">
                                      {item?.projectDetail?.projectTitle}
                                    </td>
                                    <td className="t-t-c">{item?.title}</td>
                                    <td>
                                      {
                                        currencies[
                                          item?.projectDetail?.projectCurrency?.toUpperCase()
                                        ].symbol
                                      }
                                      {/* {numeral(item?.amount).format("0,00a")} */}
                                      {numberWithCommas(item?.amount)}
                                    </td>
                                    <td className={`t-t-c ${item?.status}`}>
                                      {item.status}
                                    </td>
                                    <td
                                      className="c-p"
                                      onClick={(e) => {
                                        window.open(
                                          `${pdfUrl}${item?.invoice}`
                                        );
                                        {
                                          e.stopPropagation();
                                        }
                                      }}
                                    >
                                      <span>Open PDF</span>
                                      <FaFilePdf className="close-icon" />
                                      <span></span>
                                    </td>
                                    <td>
                                      {item?.pdfPassword ? (
                                        <CopyToClipboard
                                          text={item?.pdfPassword}
                                          onCopy={() =>
                                            toast.success(
                                              "Password copied to clipboard."
                                            )
                                          }
                                        >
                                          <div className="c-p">
                                            <AiOutlinePaperClip
                                              style={{ fontSize: "20px" }}
                                            />
                                            <span>(Click to Copy)</span>
                                          </div>
                                        </CopyToClipboard>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {widthdrawlRequests.length === 0 && !isApicall && (
                    <div className="p-d-null-data">
                      <NullDataComponent
                        c2
                        text={"No widthdrawl Requests yet."}
                      />
                    </div>
                  )}
                </Col>
                <Col lg={5}>
                  <div className="admin-message">
                    <h2>Messages</h2>
                    <div className="admin-messsage-box">
                      {messageData.length > 0 ? (
                        <>
                          <div className="admin-messsage-box2">
                            {messageData.map((item, index) => (
                              <>
                                <div
                                  className="admin-messsage-box-card c-p"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/admin/ChatScreen",
                                      state: {
                                        userId: item?.from?._id,
                                        projectId: item?.room?.projectId?._id,
                                        proposalId: item?.room?.proposalId,
                                        userName: item?.from?.name,
                                        userRole: item?.from?.role,
                                      },
                                    })
                                  }
                                >
                                  <div className="message-header">
                                    <div className="chat_user_img">
                                      <img
                                        src={`${imageUrl}${item?.from?.photo}`}
                                        alt="icon"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = fallbackUser;
                                        }}
                                      />
                                    </div>
                                    <div className="admin-messsage-innerbox1">
                                      <p className="xxsmall">
                                        {item?.from?.name}
                                      </p>
                                      <p className="gray xxsmall">
                                        {moment(item?.updatedAt).format(
                                          "hh:mm A"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="admin-messsage-innerbox2">
                                    {/* <p>{item?.message?.text}</p> */}
                                    {item?.message?.text?.length > 0 && (
                                      <p>{item?.message?.text}</p>
                                    )}
                                    {item?.type == "media" && (
                                      <img
                                        src={calculateImagePath(
                                          item?.src,
                                          item?.mediaType
                                        )}
                                        className="w-50-image"
                                        onClick={() =>
                                          validatePath(
                                            item?.src,
                                            item?.mediaType
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div className="p-d-null-data msg_null_box">
                          <NullDataComponent sm text={"No Messages yet."} />
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  {assignedProjects.length > 0 && (
                    <Row>
                      <Col lg={12}>
                        <div className="Dashboardpage-invoice">
                          <h2>Projects</h2>
                          <div className="Dashboardpage-invoice-btn">
                            <button
                              onClick={() =>
                                history.push("/admin/assignProject")
                              }
                            >
                              View More
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="invoice-Table">
                          <table>
                            <thead className="invoice-head">
                              <tr>
                                <th>No.</th>
                                <th>Project Title</th>
                                <th>Budget</th>
                                <th>Status</th>
                                <th>Funds Released & Completion</th>
                                <th>Project Completion</th>
                                <th>Days Left</th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedProjects.map((item, index) => {
                                let progressClass = "danger";
                                let progressValue =
                                  (item.amountPayedToAdmin / item.amount) * 100;

                                if (progressValue <= 10)
                                  progressClass = "danger";
                                else if (
                                  progressValue > 10 &&
                                  progressValue <= 30
                                )
                                  progressClass = "warning";
                                else if (
                                  progressValue > 30 &&
                                  progressValue <= 80
                                )
                                  progressClass = "primary";
                                else if (
                                  progressValue > 80 &&
                                  progressValue >= 100
                                )
                                  progressClass = "success";
                                else progressClass = "danger";

                                // free
                                let progressClassF = "danger";
                                let progressValueF =
                                  (item.amountPayedToFreelancer / item.amount) *
                                  100;
                                if (progressValueF <= 10)
                                  progressClassF = "danger";
                                else if (
                                  progressValueF > 10 &&
                                  progressValueF <= 30
                                )
                                  progressClassF = "warning";
                                else if (
                                  progressValueF > 30 &&
                                  progressValueF <= 80
                                )
                                  progressClassF = "primary";
                                else if (
                                  progressValueF > 80 &&
                                  progressValueF >= 100
                                )
                                  progressClassF = "success";
                                else progressClassF = "danger";

                                return (
                                  <tr
                                    className="invoice-row projectsection c-p"
                                    key={index}
                                    onClick={() =>
                                      history.push({
                                        pathname: "/admin/detailPage",
                                        state: item._id,
                                      })
                                    }
                                  >
                                    <td>{index + 1}</td>
                                    <td className="t-t-c">{item.title}</td>

                                    <td>
                                      {
                                        currencies[item.currency.toUpperCase()]
                                          .symbol
                                      }
                                      {/* {numeral(item?.amount).format("0,00a")} */}
                                      {numberWithCommas(item?.amount)}
                                    </td>
                                    <td
                                      className={`t-t-c ${item.projectStatus}`}
                                    >
                                      {item.projectStatus?.replace(
                                        formRegEx,
                                        formRegExReplacer
                                      )}
                                    </td>
                                    {/* <td 
                                      onClick={() =>
                                        window.open(`${pdfUrl}${item?.invoice}`)
                                      }
                                    >
                                      <span>Download PDF</span>
                                      <FaFilePdf className="close-icon" />
                                      <span></span>
                                    </td> */}

                                    {/* cus */}
                                    <td>
                                      <div className="innerspan">
                                        <span className="value-box">
                                          {parseFloat(
                                            progressValue > 100
                                              ? 100
                                              : progressValue
                                          ).toFixed(0)}
                                          %
                                        </span>
                                        <span className="progress">
                                          <ProgressBar
                                            variant={progressClass}
                                            now={progressValue}
                                            className={progressClass}
                                          />
                                        </span>
                                      </div>
                                    </td>

                                    {/* fre */}
                                    <td>
                                      <div className="innerspan">
                                        <span className="value-box">
                                          {parseFloat(
                                            progressValueF >= 100
                                              ? 100
                                              : progressValueF
                                          ).toFixed(0)}
                                          %
                                        </span>
                                        <ProgressBar
                                          variant={progressClassF}
                                          now={progressValueF}
                                          className={progressClassF}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {item?.noOfDays && progressValueF >= 100
                                        ? "-"
                                        : item?.noOfDays
                                        ? `${item?.noOfDays}`
                                        : "-"}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {assignedProjects.length === 0 && !isApicall && (
                    <div className="p-d-null-data">
                      <NullDataComponent c4 text={"No Project Assigned yet."} />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {isApicall && <BodyLoader />}
      {isLoadingGraph && <BodyLoader />}
    </>
  );
}
