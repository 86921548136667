import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ModalSkeleton from "./ModalSkeleton";

function AreYouSureModal({
  open,
  setOpen,
  title,
  handleYes,
  isLoading,
  modalbox2 = false,
}) {
  return (
    <>
      <div className="modal_areYouSure">
        <ModalSkeleton
          open={open}
          setOpen={setOpen}
          maxContent
          className={`areYourSureModal`}
          areyousure={true}
        >
          <section className="are-you-sure-modal">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className={`modal-box ${`${modalbox2}` && `modalbox2`}`}>
                    <h5>{title}</h5>
                    <div className="btns-box">
                      <button
                        className="no-btn"
                        onClick={() => setOpen(false)}
                        disabled={isLoading}
                        style={{
                          width: "100px",
                        }}
                      >
                        No
                      </button>
                      <button
                        className="yes-btn"
                        onClick={handleYes}
                        disabled={isLoading}
                        style={{
                          width: "100px",
                        }}
                      >
                        {isLoading ? "Please Wait..." : "Yes"}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </ModalSkeleton>
      </div>
    </>
  );
}

export default AreYouSureModal;
