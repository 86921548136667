import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminSideBar from "../components/AdminSideBar";
import AreYouSureModal from "../components/AreYouSureModal";
import {
  apiHeader,
  BaseURL,
  groupArrayOfObjects,
  imageUrl,
  formRegEx,
  formRegExReplacer,
} from "../Config/apiUrl";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import BodyLoader from "../components/BodyLoader";
import AddOrUpdateCrudModal from "../components/AddOrUpdateCrudModal";
import {
  AiFillLinkedin,
  AiFillTwitterCircle,
} from "react-icons/ai";
import { FaGooglePlusG, FaShare } from "react-icons/fa";
import ShowMoreShowLessText from "../components/ShowMoreShowLessText";
import { toast } from "react-toastify";

const Cruds = () => {
  const { access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  //   get api states
  const [data, setData] = useState([]);
  const [isApiCall, setIsApiCall] = useState(false);

  //   update apiStates
  const [isAddOrUpdateApiCall, setIsUpdateApiCall] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [isAdAndUpdateModalOpen, setIsAdAndUpdateModalOpen] = useState(false);

  // delete satates
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);

  //   ------------------------------------------------ handlers -------------------------------------------------
  // handleGetApiCall
  const handleGetApiCall = async () => {
    const url = BaseURL("page");
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      let crudArray = response.data?.data;
      let groupedArray = groupArrayOfObjects(crudArray, "secName");

      let _company = groupedArray["company"];
      let _onboarding_process = groupedArray["onboarding-process"];
      let _our_services = groupedArray["our-services"];
      let _how_to_order = groupedArray["how-to-order"];
      let _testimonials = groupedArray["testimonials"];
      let _our_latest_news = groupedArray["our-latest-news"];
      let _social_icons = groupedArray["social-icons"];
      let _greatest_asset_time = groupedArray["greatest-asset-time"];
      let _quantities_for = groupedArray["quantities-for"];
      let _our_experts = groupedArray["our-experts"];

      setData([
        ..._company,
        ..._onboarding_process,
        ..._our_services,
        ..._how_to_order,
        ..._testimonials,
        ..._our_latest_news,
        ..._social_icons,
        ..._greatest_asset_time,
        ..._quantities_for,
        ..._our_experts,
      ]);
    }
  };

  //   handleAddOrUpdate
  const handleAddOrUpdate = async (params, emptyStates) => {
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required.`
        );
    const formData = new FormData();
    for (let key in params) formData.append(key, params[key]);

    const url = updateItem
      ? BaseURL(`page/${updateItem?._id}`)
      : BaseURL("page");

    const fn = updateItem ? Patch : Post;

    setIsUpdateApiCall(true);
    const response = await fn(url, formData, apiHeader(accessToken, true));
    setIsUpdateApiCall(false);

    if (response !== undefined) {
      setIsAdAndUpdateModalOpen();
      toast.success(`Item ${updateItem ? "updated" : "added"} successfully.`);
      emptyStates();

      //   if we added the item
      if (!updateItem) {
        let crudArray = [...data, response.data.data];
        let groupedArray = groupArrayOfObjects(crudArray, "secName");

        let _company = groupedArray["company"];
        let _onboarding_process = groupedArray["onboarding-process"];
        let _our_services = groupedArray["our-services"];
        let _how_to_order = groupedArray["how-to-order"];
        let _testimonials = groupedArray["testimonials"];
        let _our_latest_news = groupedArray["our-latest-news"];
        let _social_icons = groupedArray["social-icons"];
        let _greatest_asset_time = groupedArray["greatest-asset-time"];
        let _quantities_for = groupedArray["quantities-for"];
        let _our_experts = groupedArray["our-experts"];

        setData([
          ..._company,
          ..._onboarding_process,
          ..._our_services,
          ..._how_to_order,
          ..._testimonials,
          ..._our_latest_news,
          ..._social_icons,
          ..._greatest_asset_time,
          ..._quantities_for,
          ..._our_experts,
        ]);
      }

      //   if we updated
      if (updateItem) {
        const dataCopy = [...data];
        const index = dataCopy.findIndex((e) => e?._id === updateItem?._id);
        dataCopy[index] = response?.data?.data;
        setData(dataCopy);
      }

      setUpdateItem(null);
    } else {
      for (let key in params) formData.delete(key);
    }
  };

  //   handleDeleteApiCall
  const handleDeleteApiCall = async () => {
    const url = BaseURL(`page/${deleteItemId}`);
    setIsDeleteApiCall(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const filteredData = data.filter((e) => e?._id !== deleteItemId);
      setData(filteredData);
      setIsAreYouSureModalOpen(false);
      toast.success("Item deleted successfully.");
      setDeleteItemId(null);
    }
  };

  //   onload fire effect
  useEffect(() => {
    handleGetApiCall();
  }, []);

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              {/* add btn */}
              <div className="add-btn-container">
                <button
                  className="btn-detail"
                  onClick={() => {
                    setUpdateItem(null);
                    setIsAdAndUpdateModalOpen(true);
                  }}
                >
                  Add
                </button>
              </div>
              <div className="invoice-Table crud-table-x">
                <table>
                  {/* <thead className="invoice-head">
                    <tr>
                      <th>No.</th>
                      <th>Title</th>
                      <th>Sub Title</th>
                      <th>Description</th>
                      <th>Rating</th>
                      <th>Image</th>
                      <th>Cover Image</th>
                      <th>Section Name</th>
                      <th>Social Links</th>
                      <th>Actions</th>
                    </tr>
                  </thead> */}
                  <>
                    {data?.map((e, i) => (
                      <div key={i} className="table-main-row">
                        {e?.secName !== data[i - 1]?.secName && (
                          <h2 className="t-t-c mb-3">
                            {e?.secName?.replaceAll("-", " ")}
                          </h2>
                        )}

                        <thead
                          className={`${
                            e?.secName !== data[i - 1]?.secName
                              ? "invoice-head"
                              : "invoice-head custom-class"
                          }`}
                        >
                          <tr>
                            <th>No.</th>
                            <th>Title</th>
                            <th>Sub Title</th>
                            <th>Description</th>
                            <th>Rating</th>
                            <th>Image</th>
                            <th>Cover Image</th>
                            <th>Section Name</th>
                            <th>Social Links</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody className="">
                          <tr className="invoice-row c-p" key={e?._id}>
                            <td>{i}</td>
                            <td>{e?.title || "-"}</td>
                            <td>{e?.subTitle || "-"}</td>
                            <td>
                              {e?.description ? (
                                <ShowMoreShowLessText
                                  text={e?.description}
                                  visibility={10}
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>{e?.rating || "-"}</td>
                            <td>
                              {e?.image ? (
                                <img
                                  src={`${imageUrl}${e?.image}`}
                                  alt="img"
                                  className="w-custom-new"
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {e?.coverImage ? (
                                <img
                                  src={`${imageUrl}${e?.coverImage}`}
                                  alt="img"
                                  className="w-custom-new"
                                />
                              ) : (
                                "-"
                              )}
                            </td>

                            <td className="t-t-c">
                              {e?.secName?.replaceAll("-", " ")}
                            </td>

                            <td>
                              <div className="d-flex">
                                {e?.linkedIn && <AiFillLinkedin />}
                                {e?.twitter && <AiFillTwitterCircle />}
                                {e?.googlePlus && <FaGooglePlusG />}
                                {(e?.sharableLink || e?.socialUrl) && (
                                  <FaShare
                                    onClick={() =>
                                      window.open(e?.socialUrl, "_blank")
                                    }
                                  />
                                )}
                              </div>
                            </td>

                            <td>
                              <div className="d-flex">
                                <button
                                  className="btn-detail m-0 me-3"
                                  onClick={() => {
                                    setIsAdAndUpdateModalOpen(true);
                                    setUpdateItem(e);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn-detail m-0 c-r"
                                  onClick={() => {
                                    setIsAreYouSureModalOpen(true);
                                    setDeleteItemId(e?._id);
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>

                        {e?.secName !== data[i + 1]?.secName && (
                          <div
                            style={{
                              marginBottom: "50px",
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* handleAddOrUpdate */}
      <AddOrUpdateCrudModal
        showModal={isAdAndUpdateModalOpen}
        closeModal={() => setIsAdAndUpdateModalOpen(false)}
        isLoading={isAddOrUpdateApiCall}
        data={updateItem}
        handleSubmit={handleAddOrUpdate}
      />

      {/* handleDeleteApiCall */}
      {isAreYouSureModalOpen && (
        <AreYouSureModal
          setOpen={setIsAreYouSureModalOpen}
          open={isAreYouSureModalOpen}
          handleYes={handleDeleteApiCall}
          title={"Are You Sure? Do you really want to delete this item?"}
          isLoading={isDeleteApiCall}
        />
      )}

      {isApiCall && <BodyLoader />}
    </>
  );
};

export default Cruds;
