import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { useSelector } from "react-redux";
import {
  apiHeader,
  BaseURL,
  formRegExReplacer,
  recordsLimit,
  formRegEx,
  imageUrl,
} from "../Config/apiUrl";
import { Get, Post, Patch, Delete } from "../Axios/AxiosFunctions";
import AdminSideBar from "../components/AdminSideBar";
import PaginationB from "../components/PaginationB";
import BodyLoader from "../components/BodyLoader";
import { toast } from "react-toastify";
import AddOrUpdateAnnouncementModal from "../components/AddOrUpdateAnnouncementModal";
import AreYouSureModal from "../components/AreYouSureModal";
var moment = require("moment");

export default function Contractors() {
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  const [announcements, setAnnouncements] = useState([]);
  const [isApicall, setIsApicall] = useState(false);

  const [isActivating, setIsActivating] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);

  const [isAddOrUpdateApiCall, setIsUpdateApiCall] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [isAdAndUpdateModalOpen, setIsAdAndUpdateModalOpen] = useState(false);

  // delete satates
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [status, setStatus] = useState("all");
  const newAnnouncementsArr = announcements.filter(function (item) {
    if (status === "all") {
      return item;
    } else {
      return item?.isActive === (status === "active" ? true : false);
    }
  });

  async function getData(_page) {
    const url = BaseURL(
      `admin/announcement?limit=${recordsLimit}&page=${_page}`
    );
    setIsApicall(true);
    const response = await Get(url, accessToken);
    setIsApicall(false);
    if (response !== undefined) {
      setAnnouncements(response?.data.data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
  }

  useEffect(() => {
    getData(page);
  }, [page]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // changeSttaus
  const handleStatusChange = async (id, status) => {
    const url = BaseURL(`admin/announcement/active-deactive/${id}`);
    status == "activated" && setIsActivating(id);
    status == "deactivated" && setIsDeactivating(id);
    const response = await Patch(
      url,
      { status: status == "activated" ? true : false },
      apiHeader(accessToken)
    );
    status == "activate" && setIsActivating(null);
    status == "deactivate" && setIsDeactivating(null);

    if (response !== undefined) {
      toast.success(`Item ${status} successfully.`);
      getData(page);
    }
  };

  //   handleAddOrUpdate
  const handleAddOrUpdate = async (params) => {
    for (let key in params)
      if (key != "link" && (params[key] === "" || params[key] === null))
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required.`
        );

    const formData = new FormData();
    for (let key in params) formData.append(key, params[key]);

    const url = updateItem
      ? BaseURL(`admin/announcement/${updateItem?._id}`)
      : BaseURL("admin/announcement");

    const fn = updateItem ? Patch : Post;

    setIsUpdateApiCall(true);
    const response = await fn(url, formData, apiHeader(accessToken, true));
    setIsUpdateApiCall(false);

    if (response !== undefined) {
      setIsAdAndUpdateModalOpen();
      toast.success(`Item ${updateItem ? "updated" : "added"} successfully.`);

      getData(page);

      setUpdateItem(null);
    } else {
      for (let key in params) formData.delete(key);
    }
  };

  //   handleDeleteApiCall
  const handleDeleteApiCall = async () => {
    const url = BaseURL(`admin/announcement/${deleteItemId}`);
    setIsDeleteApiCall(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      setIsAreYouSureModalOpen(false);
      toast.success("Item deleted successfully.");
      setDeleteItemId(null);
      getData(page);
    }
  };

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <div className="Dashboardpage-invoice mt-0">
                    <h2>Announcements</h2>
                    <select
                      className="dropdown-style-x"
                      onChange={(e) => setStatus(e?.target?.value)}
                      value={status}
                    >
                      {["all", "active", "deactive"].map((e) => (
                        <option key={e} value={e}>
                          {e === "deactive"
                            ? "Not Active"
                            : e.replace(formRegEx, formRegExReplacer)}
                        </option>
                      ))}
                    </select>
                    <div className="add-btn-container">
                      <button
                        className="btn-detail"
                        onClick={() => {
                          setUpdateItem(null);
                          setIsAdAndUpdateModalOpen(true);
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="invoice-Table invoive-table-x">
                    <table>
                      <thead className="invoice-head">
                        <tr>
                          <th>No.</th>
                          <th>Title</th>
                          {/* <th>Link</th> */}
                          <th>Image</th>
                          <th>Status</th>
                          <th>Created On</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newAnnouncementsArr?.map((item, index) => {
                          return (
                            <tr className="invoice-row projectsection invoive-tr-x">
                              <td>{index + 1}</td>
                              <td className="t-t-c">{item?.title}</td>
                              {/* <td className="t-t-c">{item?.link}</td> */}
                              <td
                                className="t-t-c c-p"
                                onClick={() => {
                                  window.open(`${item?.link}`);
                                }}
                              >
                                {" "}
                                {item?.image ? (
                                  <img
                                    src={`${imageUrl}${item?.image}`}
                                    alt="img"
                                    className="w-custom-new"
                                  />
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td className="t-t-c">
                                {item?.isActive ? "active" : "Not Active"}
                              </td>
                              <td>
                                {moment(item?.createdAt).format("YYYY-MM-DD")}
                              </td>
                              <td className="d-flex justify-content-between">
                                {!item?.isActive && (
                                  <button
                                    className="btn-detail m-0 btn_x"
                                    onClick={() =>
                                      handleStatusChange(item?._id, "activated")
                                    }
                                    disabled={item?.isActive}
                                  >
                                    {isActivating == item?._id
                                      ? "Activating"
                                      : "Activate"}
                                  </button>
                                )}
                                {item.isActive && (
                                  <button
                                    className="btn-detail m-0 c-r"
                                    onClick={() =>
                                      handleStatusChange(
                                        item?._id,
                                        "deactivated"
                                      )
                                    }
                                    disabled={!item?.isActive}
                                  >
                                    {isDeactivating == item?._id
                                      ? "Not Active"
                                      : "Deactivate"}
                                  </button>
                                )}
                                <button
                                  className="btn-detail m-0"
                                  onClick={() => {
                                    setIsAdAndUpdateModalOpen(true);
                                    setUpdateItem(item);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn-detail m-0 c-r"
                                  onClick={() => {
                                    setIsAreYouSureModalOpen(true);
                                    setDeleteItemId(item?._id);
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {totalCount > 1 && (
                      <PaginationB
                        page={page}
                        setPage={setPage}
                        totalCount={totalCount}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {isAdAndUpdateModalOpen && (
        <AddOrUpdateAnnouncementModal
          showModal={isAdAndUpdateModalOpen}
          closeModal={() => setIsAdAndUpdateModalOpen(false)}
          isLoading={isAddOrUpdateApiCall}
          data={updateItem}
          handleSubmit={handleAddOrUpdate}
        />
      )}

      {isAreYouSureModalOpen && (
        <AreYouSureModal
          setOpen={setIsAreYouSureModalOpen}
          open={isAreYouSureModalOpen}
          handleYes={handleDeleteApiCall}
          title={"Are You Sure? Do you really want to delete this item?"}
          isLoading={isDeleteApiCall}
        />
      )}

      {isApicall && <BodyLoader />}
    </>
  );
}
