import React, { useState, useEffect } from "react";
import AdminSideBar from "../components/AdminSideBar";
import { Container, Row, Col, } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Get } from "../Axios/AxiosFunctions";
import { BaseURL } from "../Config/apiUrl";
import { useSelector } from "react-redux";
import BodyLoader from "../components/BodyLoader";

function Cms_Pages() {
  const history = useHistory();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isApicall, setIsApicall] = useState(false);
  const [tableData, setTableData] = useState([]);

  // getApiData
  const getApiDAta = async () => {
    setIsApicall(true);

    const url = BaseURL(`cms/pages/all?all=true`);
    const response = await Get(url, accessToken);
    setIsApicall(false);
    if (response !== undefined) {
      setTableData(response?.data?.data);
    }
  };

  useEffect(() => {
    getApiDAta();
  }, []);

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <h3>Pages :</h3>
                </Col>
                <Col lg={12}>
                  <div className="invoice-Table">
                    <table>
                      <thead className="invoice-head">
                        <tr>
                          <th>No.</th>
                          <th>Pages Name</th>
                          <th colspan="2">Action</th>
                          {/* <th></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item, index) => (
                          <tr className="invoice-row">
                            <td>{index + 1}</td>
                            <td className="t-t-c">
                              {item?.pageName?.replaceAll("_", " ")}
                            </td>
                            <td>
                              <div className="table-detail-btn edit-btn">
                                <button
                                  onClick={() => {
                                    let pathName;
                                    item?.pageName === "home" &&
                                      (pathName = "/admin/home-cms");
                                    item?.pageName === "services" &&
                                      (pathName = "/admin/services-cms");
                                    item?.pageName === "order" &&
                                      (pathName = "/admin/order-cms");
                                    item?.pageName === "about_us" &&
                                      (pathName = "/admin/about-cms");
                                    item?.pageName === "contact_us" &&
                                      (pathName = "/admin/contactus-cms");
                                    item?.pageName === "footer" &&
                                      (pathName = "/admin/footer-cms");
                                    item?.pageName === "terms_and_condition" &&
                                      (pathName = "/admin/support-cms");
                                    item?.pageName === "privacy_policy" &&
                                      (pathName = "/admin/support-cms");
                                    item?.pageName === "privacy_policy_2" &&
                                      (pathName = "/admin/support-cms");
                                    history.push({
                                      state: {
                                        pageId: item?._id,
                                        pageName: item?.pageName,
                                      },
                                      pathname: pathName,
                                    });
                                  }}
                                >
                                  Edit
                                </button>
                              </div>
                            </td>
                            {/* <td>
                              <div className="table-detail-btn edit-btn">
                                <button>SEO</button>
                              </div>
                            </td> */}
                          </tr>
                        ))}
                        {/* <tr className="invoice-row">
                          <td>1</td>
                          <td>HomePage</td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button onClick={()=>{
                                      history.push({
                                        pathname: "/admin/home-cms",
                                        state: {},
                                      });
                              } }>Edit</button>
                            </div>
                          </td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button>SEO</button>
                            </div>
                          </td>
                        </tr> */}
                        {/* <tr className="invoice-row">
                          <td>2</td>
                          <td>Services Page</td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button onClick={()=> history.push("admin/services-cms")}>Edit</button>
                            </div>
                          </td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button>SEO</button>
                            </div>
                          </td>
                        </tr>
                        <tr className="invoice-row">
                          <td>3</td>
                          <td>Order Page</td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button onClick={()=> history.push("/admin/order-cms")}>Edit</button>
                            </div>
                          </td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button>SEO</button>
                            </div>
                          </td>
                        </tr>
                        <tr className="invoice-row">
                          <td>4</td>
                          <td>About Page</td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button onClick={()=> history.push("/admin/about-cms")}>Edit</button>
                            </div>
                          </td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button>SEO</button>
                            </div>
                          </td>
                        </tr>
                        <tr className="invoice-row">
                          <td>5</td>
                          <td>Contact Page</td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button onClick={()=> history.push("/admin/contactus-cms")}>Edit</button>
                            </div>
                          </td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button>SEO</button>
                            </div>
                          </td>
                        </tr>
                        <tr className="invoice-row">
                          <td>6</td>
                          <td>Footer</td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button onClick={()=> history.push("/admin/footer-cms")}>Edit</button>
                            </div>
                          </td>
                          <td>
                            <div className="table-detail-btn edit-btn">
                              <button>SEO</button>
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {isApicall && <BodyLoader />}
    </>
  );
}

export default Cms_Pages;
