import moment from "moment";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ModalSkeleton from "./ModalSkeleton";

import { AiOutlineFilePdf } from "react-icons/ai";
import { fallbackUser, imageUrl, pdfUrl } from "../Config/apiUrl";

function ConsultantDetailModal({
  open,
  setOpen,
  data,
  isLoading,
  modalbox2 = false,
}) {
  return (
    <>
      <div className="modal_areYouSure ">
        <ModalSkeleton
          open={open}
          setOpen={setOpen}
          maxContent
          className={`areYourSureModal info-modal-x`}
        >
          <section className="are-you-sure-modal">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className={`modal-box ${`${modalbox2}` && `modalbox2`}`}>
                    <h2>
                      {data?.role === "freelancer" ? "Consultant" : "Client"}{" "}
                      Details
                    </h2>

                    <Col md={12}>
                      <img
                        src={imageUrl + data?.photo}
                        className="user-modal-img"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = fallbackUser;
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <p>
                        <b>Name: </b>
                        {data?.name}
                      </p>
                    </Col>
                    <Col md={12}>
                      <p>
                        <b>Email: </b>
                        {data?.email}
                      </p>
                    </Col>
                    {data?.role != "freelancer" && (
                      <>
                        <Col md={12}>
                          <p>
                            <b>Company: </b>
                            {data?.company}
                          </p>
                        </Col>
                        <Col md={12}>
                          <p>
                            <b>Job/Position: </b>
                            {data?.job}
                          </p>
                        </Col>
                        <Col md={12}>
                          <p>
                            <b>Location: </b>
                            {data?.address}
                          </p>
                        </Col>
                        <Col md={12}>
                          <p>
                            <b>Company website: </b>
                            <a href={`${data?.companyWebsite}`} target="_blank">
                              {data?.companyWebsite}
                            </a>
                          </p>
                        </Col>
                        <Col md={12}>
                          <p>
                            <b>Projects Submitted till date: </b>
                            {data?.allProjects}
                          </p>
                        </Col>
                        <Col md={12}>
                          <p>
                            <b>Completed Projects: </b>
                            {data?.completedProjects}
                          </p>
                        </Col>
                      </>
                    )}
                    <Col md={12}>
                      <p>
                        <b>Contact Number: </b>
                        {data?.contactNo}
                      </p>
                    </Col>
                    <Col md={12}>
                      <p>
                        <b>Last Login: </b>
                        {moment(data?.lastLogin).format("lll")}
                      </p>
                    </Col>
                    {data?.role === "freelancer" && (
                      <Col md={12}>
                        <p>
                          <b>Status: </b>
                          <span className="t-t-c">{data?.status}</span>
                        </p>
                      </Col>
                    )}
                    {data?.role === "freelancer" && (
                      <Col md={12}>
                        <p>
                          <b>Age: </b>
                          {data?.age} years old
                        </p>
                      </Col>
                    )}
                    {data?.role === "freelancer" && (
                      <Col md={12}>
                        <p>
                          <b>Qualification: </b>
                          {data?.qualification}
                        </p>
                      </Col>
                    )}
                    <Col md={12}>
                      <p>
                        <b>Created On: </b>
                        {moment(data?.createdAt).format("lll")}
                      </p>
                    </Col>
                    {data?.role === "freelancer" && (
                      <>
                        <Col md={12}>
                          <p>
                            <b>Banking Details: </b>
                            {data?.bankDetails}
                          </p>
                        </Col>
                        {data?.addressDoc && (
                          <Col md={12} className="pdfMapperContainer">
                            <p>
                              <b>Address Document: </b>
                              <div
                                onClick={() =>
                                  window.open(
                                    `${pdfUrl}${data?.addressDoc}`,
                                    "_blank"
                                  )
                                }
                              >
                                <AiOutlineFilePdf />
                              </div>
                            </p>
                          </Col>
                        )}
                        {data?.identityDoc && (
                          <Col md={12} className="pdfMapperContainer">
                            <p>
                              <b>Identity Document: </b>
                              <div
                                onClick={() =>
                                  window.open(
                                    `${pdfUrl}${data?.identityDoc}`,
                                    "_blank"
                                  )
                                }
                              >
                                <AiOutlineFilePdf />
                              </div>
                            </p>
                          </Col>
                        )}
                        {data?.qualificationDoc && (
                          <Col md={12} className="pdfMapperContainer">
                            <p>
                              <b>Qualification Document: </b>
                              <div
                                onClick={() =>
                                  window.open(
                                    `${pdfUrl}${data?.qualificationDoc}`,
                                    "_blank"
                                  )
                                }
                              >
                                <AiOutlineFilePdf />
                              </div>
                            </p>
                          </Col>
                        )}
                        {/* <p>
                          <b>Attached Documents: </b>
                        </p>
                        <Col md={12} className="pdfMapperContainer">
                          {data?.addressDoc && (
                            <div
                              onClick={() =>
                                window.open(
                                  `${pdfUrl}${data?.addressDoc}`,
                                  "_blank"
                                )
                              }
                            >
                              <AiOutlineFilePdf />
                            </div>
                          )}
                          {data?.identityDoc && (
                            <div
                              onClick={() =>
                                window.open(
                                  `${pdfUrl}${data?.identityDoc}`,
                                  "_blank"
                                )
                              }
                            >
                              <AiOutlineFilePdf />
                            </div>
                          )}
                          {data?.qualificationDoc && (
                            <div
                              onClick={() =>
                                window.open(
                                  `${pdfUrl}${data?.qualificationDoc}`,
                                  "_blank"
                                )
                              }
                            >
                              <AiOutlineFilePdf />
                            </div>
                          )}
                     
                        </Col> */}
                      </>
                    )}
                    <div className="btns-box">
                      <button
                        className="no-btn"
                        onClick={() => setOpen(false)}
                        disabled={isLoading}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </ModalSkeleton>
      </div>
    </>
  );
}

export default ConsultantDetailModal;
