import React from "react";
import ReactHtmlParser from "react-html-parser";
const ShowMoreShowLessText = ({ text, visibility = 30, quill = false }) => {
  const [isshowingMore, setIsShowingMore] = React.useState(false);

  return (
    <>
      {quill
        ? ReactHtmlParser(
            text?.substring(0, isshowingMore ? text.length : visibility)
          )
        : text?.substring(0, isshowingMore ? text.length : visibility)}
      {text?.length > visibility && !isshowingMore && "..."}{" "}
      {text?.length > visibility && (
        <span
          className="c-p"
          onClick={(e) => {
            e.stopPropagation();
            setIsShowingMore((p) => !p)
          }}
          style={{
            color: "#1CB735",
            fontWeight: "700",
          }}
        >
          {" "}
          {isshowingMore ? "Show Less" : "Show More"}
        </span>
      )}
    </>
  );
};

export default ShowMoreShowLessText;
