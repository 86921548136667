import dashboardimg1 from "../assets/images/Group 549.svg";
import Collectimg from "../assets/images/collection-add.svg";
import dashboardimg2 from "../assets/images/Group 550.svg";
import dashboardimg3 from "../assets/images/Group 551.svg";
import dashboardimg4 from "../assets/images/Group 552.svg";
import dashboardimg5 from "../assets/images/Group 553.svg";
import dashboardimg6 from "../assets/images/Group 554.png";
import crud_icon from "../assets/images/crud-icon.png";
import page_icon from "../assets/images/page-icon.png";
import workerimg from "../assets/images/Path 1551.png";
import contractorimg from "../assets/images/general-contractor-svgrepo-com.png";
import megaphone from "../assets/images/megaphone.png";
import blogging from "../assets/images/blogging.png";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { BaseURL, getSpecificSearchParam } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";

export default function AdminSideBar() {
  const loc = getSpecificSearchParam("loc");
  const { access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  const [Location, setLocation] = useState("");

  useEffect(() => {
    setLocation(window.location.pathname);
  }, []);

  // get noti Count
  const [notiCount, setNotiCount] = useState(0);
  const [chatNotifications, setChatNotifications] = useState(0);
  const getNoticount = async () => {
    const url = BaseURL("notifications/all?limit=1&page=1");
    const url2 = BaseURL("notifications/all?limit=1&page=1&isMessage=true");
    const [response, response2] = await Promise.all([
      Get(url, accessToken, false),
      Get(url2, accessToken, false),
    ]);
    if (response !== undefined) {
      setNotiCount(response.data?.newNotifications);
    }
    if (response2 !== undefined) {
      setChatNotifications(response2.data?.newNotifications);
    }
  };

  useEffect(() => {
    getNoticount();
  }, [window.location.pathname]);

  return (
    <>
      <div className="Dashboardpage-links">
        <ul>
          <li>
            <Link
              to="/admindashboard"
              className={`btn custom-btn ${
                "/admindashboard" == Location ? "active-link" : ""
              }`}
            >
              {" "}
              <span>
                <img src={dashboardimg1} alt="dashboardimg" />
              </span>{" "}
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/admin/assignProject"
              className={`btn custom-btn ${
                "/admin/assignProject" == Location ? "active-link" : ""
              } ${loc == 1 && "loc-activated"}`}
            >
              {" "}
              <span className="icon-img">
                <img src={Collectimg} alt="dashboardimg" />
              </span>
              Assigned Projects
            </Link>
          </li>
          <li>
            <Link
              to="/admin/postedProjects"
              className={`btn custom-btn ${
                "/admin/postedProjects" == Location ? "active-link" : ""
              } ${loc == 2 && "loc-activated"}`}
            >
              {" "}
              <span className="icon-img">
                <img src={dashboardimg2} alt="dashboardimg" />
              </span>
              Posted Projects
            </Link>
          </li>
          {/* <li>
            <Link to="/" className={`btn custom-btn ${
                window.location.pathname == Location ? "active-link" : ""
              }`}>
              <span>
                <img src={dashboardimg3} alt="dashboardimg" />
              </span>
              Invoices
            </Link>
          </li> */}
          <li>
            <Link
              to="/admin/payments"
              className={`btn custom-btn ${
                "/admin/payments" == Location ? "active-link" : ""
              } ${loc == 3 && "loc-activated"}`}
            >
              <span>
                <img src={dashboardimg4} alt="dashboardimg" />
              </span>
              Payments - Incoming
            </Link>
          </li>
          <li>
            <Link
              to="/admin/withdraw"
              className={`btn custom-btn ${
                "/admin/withdraw" == Location ? "active-link" : ""
              } ${loc == 4 && "loc-activated"}`}
            >
              <span>
                <img src={dashboardimg4} alt="dashboardimg" />
              </span>
              Payments to Consultants-Outgoing
            </Link>
          </li>
          <li>
            <Link
              to="/admin/workers"
              className={`btn custom-btn ${
                "/admin/workers" == Location ? "active-link" : ""
              }`}
            >
              <span>
                <img src={workerimg} alt="dashboardimg" />
              </span>
              {/* Worker */}
              Client
            </Link>
          </li>
          <li>
            <Link
              to="/admin/contractors"
              className={`btn custom-btn ${
                "/admin/contractors" == Location ? "active-link" : ""
              }`}
            >
              <span>
                <img src={contractorimg} alt="dashboardimg" />
              </span>
              {/* Contractor */}
              Consultants
            </Link>
          </li>
          {/* <li>
            <Link
              to="/admin/ChatScreen"
              className={`btn custom-btn ${
                "/admin/ChatScreen" == Location ? "active-link" : ""
              }`}
            >
              <span>
                <img src={dashboardimg5} alt="dashboardimg" />
              </span>
              Messages
            </Link>
          </li> */}
          <li>
            <Link
              to="/admin/blogs"
              className={`btn custom-btn ${
                "/admin/blogs" == Location ? "active-link" : ""
              }`}
            >
              <span>
                <img src={blogging} alt="dashboardimg" />
              </span>
              Blogs
            </Link>
          </li>
          <li>
            <Link
              to="/admin/announcements"
              className={`btn custom-btn ${
                "/admin/announcements" == Location ? "active-link" : ""
              }`}
            >
              <span>
                <img
                  src={megaphone}
                  alt="dashboardimg"
                  style={{
                    width: "30px",
                  }}
                />
              </span>
              Announcements
            </Link>
          </li>
          <li>
            <Link
              to="/admin/notifications"
              className={`btn custom-btn ${
                "/admin/notifications" == Location ? "active-link" : ""
              }
              ${loc == 5 && "loc-activated"}
              `}
            >
              <span>
                <img src={dashboardimg6} alt="dashboardimg" />
              </span>
              Notification{notiCount > 0 && "s"}{" "}
              {notiCount > 0 ? `(${notiCount})` : ""}
            </Link>
          </li>
          <li>
            <Link
              to="/admin/chat-notifications"
              className={`btn custom-btn ${
                "/admin/chat-notifications" == Location ? "active-link" : ""
              }`}
            >
              <span>
                <img src={dashboardimg6} alt="dashboardimg" />
              </span>
              Chat Alert{chatNotifications > 0 && "s"}{" "}
              {chatNotifications > 0 ? `(${chatNotifications})` : ""}
            </Link>
          </li>
          <li>
            <Link
              to="/admin/cms-crud"
              className={`btn custom-btn ${
                "/admin/cms-crud" == Location ? "active-link" : ""
              }`}
            >
              <span>
                <img className="edit-img" src={crud_icon} alt="dashboardimg" />
              </span>
              CMS Crud
            </Link>
          </li>
          <li>
            <Link
              to="/admin/cms-pages"
              className={`btn custom-btn ${
                "/admin/cms-pages" == Location ? "active-link" : ""
              }`}
            >
              <span>
                <img className="edit-img" src={page_icon} alt="dashboardimg" />
              </span>
              Pages
            </Link>
          </li>
          <li>
            <Link
              to="/admin/contact-us"
              className={`btn custom-btn ${
                "/admin/contact-us" == Location ? "active-link" : ""
              }`}
            >
              <span>
                <img className="edit-img" src={page_icon} alt="dashboardimg" />
              </span>
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
