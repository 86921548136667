import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import MileStoneModal from "./MileStoneModal";
import Select from "react-select";
import { useSelector } from "react-redux";
import { BaseURL, numberWithCommas } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { currencies } from "../currencies";
import ShowMoreShowLessText from "./ShowMoreShowLessText";
import { toast } from "react-toastify";
import TermsAndConditionsModal from "./TermsAndConditionModal";
import ReactQuill from "react-quill";
import moment from "moment";
var numeral = require("numeral");

export default function DetailModal({
  modalShow,
  handleClose1,
  type,
  sendProposal,
  isApiCall,
  detail,
  termConditionData,
  setTermConditionData,
}) {
  const { access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const [mileStoneModal, setMileStoneModal] = useState(false);
  const [termsConditionModal, setTermsConditionModal] = useState(false);

  const [proposalTitle, setProposalTitle] = useState();
  // detail?.title
  const [proposalDescription, setProposalDescription] = useState("");

  const [proposalAmount, setProposalAmount] = useState(0);
  // detail?.amount
  const [timeline, setTimeine] = useState();
  // moment(detail?.timeline, "DD-MM-YYYY").format("YYYY-MM-DD")
  const [mileStone, setMileStone] = useState([]);
  const [freelancer, setFreelancer] = useState([]);
  const [freelancerArray, setFreelancerArray] = useState([]);

  const [editedMilestoneItem, setEditedMilestoneItem] = useState({
    item: null,
    index: 0,
  });

  const handleClose = () => {
    setMileStoneModal(false);
    setEditedMilestoneItem({ item: null, index: 0 });
  };
  const handleCloseTermModal = () => {
    setTermsConditionModal(false);
  };

  async function getData() {
    const url = BaseURL("admin/contractors");
    const response = await Get(url, accessToken);
    if (response !== undefined) {
      setFreelancerArray(response?.data.data);
      // setFreelancer(response?.data.data[0].name);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const resetStates = () => {
    setProposalTitle("");
    setProposalDescription("");
    setProposalAmount("");
    setMileStone("");
    setFreelancer("");
    setTimeine("");
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={handleClose1}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Proposal For{" "}
            {type == "freelancer" ? "Consultant" : "Client"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <label>Proposal Title :</label> <br />
            <input
              className="modal-inp mt-2"
              type={"text"}
              placeholder={"Enter Proposal"}
              value={proposalTitle}
              onChange={(e) => setProposalTitle(e.target.value)}
            />
          </p>
          <p>
            <label>Proposal Description :</label> <br />
            {/* <textarea
              className="modal-inp mt-2 w-100 "
              placeholder={"Enter Description"}
              value={proposalDescription}
              onChange={(e) => setProposalDescription(e.target.value)}
            /> */}
            <ReactQuill
              className="modal-inp mt-2 w-100 "
              value={proposalDescription}
              onChange={(e) => setProposalDescription(e)}
            />
          </p>
          <p>
            <label>Proposal Amount :</label> <br />
            <input
              className="modal-inp mt-2"
              type={"number"}
              placeholder={"Enter Amount"}
              value={proposalAmount}
              onChange={(e) => setProposalAmount(e.target.value)}
            />
          </p>
          <p>
            <label>Project End Date :</label> <br />
            <input
              className="modal-inp mt-2"
              type={"date"}
              placeholder={""}
              value={timeline}
              min={moment().format("DD-MM-YYYY")}
              onChange={(e) => setTimeine(e.target.value)}
            />
          </p>
          {type == "freelancer" && (
            <p>
              <label>Select Consultant :</label> <br />
              <Select
                className="basic-multi-select mt-2"
                options={freelancerArray}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                value={freelancer}
                isMulti
                onChange={(e) => setFreelancer(e)}
              />
            </p>
          )}
          {mileStone.length > 0 && (
            <div className="invoice-Table">
              <table>
                <thead className="invoice-head">
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Amount</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {mileStone?.length > 0 && (
                    <>
                      {mileStone.map((item, i) => (
                        <>
                          <tr
                            className="invoice-row projectsection c-p"
                            key={i}
                          >
                            <td>{item?.title}</td>
                            <td>
                              <ShowMoreShowLessText
                                text={item?.description}
                                visibility={15}
                              />
                            </td>
                            <td>
                              {/* {item?.amount} */}
                              {detail?.currency &&
                                currencies[detail?.currency.toUpperCase()]
                                  .symbol}
                              {/* {numeral(item?.amount).format("0,00a")} */}
                              {numberWithCommas(item?.amount)}
                            </td>
                            <td className="d-flex">
                              <button
                                className="btn-detail m-0 me-2"
                                onClick={() => {
                                  setEditedMilestoneItem({
                                    item: item,
                                    index: i,
                                  });
                                  setMileStoneModal(true);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="btn-detail m-0"
                                onClick={() =>
                                  setMileStone((p) =>
                                    p.filter((ele, ind) => ind !== i)
                                  )
                                }
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          )}

          <div className="w-100 d-flex justify-content-center ">
            <button
              className="modal-btn mt-3 px-5"
              onClick={() => {
                setMileStoneModal(true);
              }}
            >
              Add MileStone
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="modal-btn"
            disabled={isApiCall}
            onClick={async () => {
              if (type == "freelancer" && freelancer?.length === 0) {
                return toast.error("Please Consultant", {
                  position: "top-center",
                });
              }
              if (type == "freelancer") {
                await sendProposal(
                  proposalTitle,
                  proposalDescription,
                  proposalAmount,
                  mileStone,
                  freelancer,
                  resetStates,
                  timeline,
                  detail
                );
              } else {
                setTermsConditionModal(true);
              }
            }}
          >
            {isApiCall ? "please wait...." : "Send Proposal"}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Milestone modal */}
      {mileStoneModal && (
        <MileStoneModal
          mileStoneModal={mileStoneModal}
          handleClose={handleClose}
          setMileStone={setMileStone}
          type={type}
          editedMilestoneItem={editedMilestoneItem}
          mileStone={mileStone}
          proposalAmount={proposalAmount}
        />
      )}

      <TermsAndConditionsModal
        mileStoneModal={termsConditionModal}
        handleClose={handleCloseTermModal}
        type={type}
        termConditionData={termConditionData}
        setTermConditionData={setTermConditionData}
        handleClose1={handleClose1}
        sendProposal={sendProposal}
        proposalTitle={proposalTitle}
        proposalDescription={proposalDescription}
        proposalAmount={proposalAmount}
        timeline={timeline}
        mileStone={mileStone}
        freelancer={freelancer}
        isApiCall={isApiCall}
        resetStates={resetStates}
      />
    </>
  );
}
