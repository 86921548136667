import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Form, } from "react-bootstrap";
import { CardMedia } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { green } from "@mui/material/colors";
import AdminSideBar from "../components/AdminSideBar";
import AddImageComponent from "../components/AddImage";
import { Get, Post } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, imageUrl, urlToObject } from "../Config/apiUrl";
import BodyLoader from "../components/BodyLoader";
import CircularLoader from "../components/CircularLoader";

function Home_cms() {
  const location = useLocation();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isloading, setIsloading] = useState(false);

  const {
    state: { pageName, pageId },
  } = location;
  const accessToken = useSelector((state) => state.authReducer.access_token);

  //  section1 states

  const [sec1Heading, setSec1Heading] = useState("");
  const [sec1Description, setSec1Description] = useState("");
  const [sec1image1, setSec1image1] = useState({});
  const [sec1image2, setSec1image2] = useState({});

  //  section2 states

  const [sec2Heading, setSec2Heading] = useState("");
  const [sec2Description, setSec2Description] = useState("");

  //  section3 states

  const [sec3Heading, setSec3Heading] = useState("");
  const [sec3Description, setSec3Description] = useState("");

  //  section4 states

  // VIDEO
  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedVideoFile, setSelectedVideoFile] = useState(null);

  //  section5 states

  const [sec5Heading, setSec5Heading] = useState("");

  //  section6 states

  const [sec6Heading, setSec6Heading] = useState("");

  //  section7 states

  const [sec7Heading, setSec7Heading] = useState("");

  //  section8 states

  const [sec8Heading, setSec8Heading] = useState("");
  const [sec8Description, setSec8Description] = useState("");

  const [videoProgress, setVideoProgress] = useState("");

  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName: pageName,

      sec1Heading: sec1Heading,
      sec1Description: sec1Description,
      sec1CoverImage: sec1image1,
      image: sec1image2,

      sec2Heading: sec2Heading,
      sec2Description: sec2Description,

      sec3Heading: sec3Heading,
      sec3Description: sec3Description,

      sec5Heading: sec5Heading,

      sec6Heading: sec6Heading,

      sec7Heading: sec7Heading,

      sec8Heading: sec8Heading,
      sec8Description: sec8Description,
    };

    const formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }

    if (selectedVideoFile) {
      formData.sec4Video = selectedVideoFile;
      formData.append("isVideo", true);
    } else {
      formData.append("isVideo", false);
    }

    //  post Api home Page

    const url = BaseURL("cms/page/update");
    setIsloading(true);
    const response = await Post(url, formData, apiHeader(accessToken));
    selectedVideoFile &&
      (await awsVideoUploader(response?.data?.url, selectedVideoFile));
    setIsloading(false);

    if (response !== undefined) {
      toast.success("Home Page data updated successfully");
    }
  };

  // Get api home page
  const getData = async () => {
    const url = BaseURL(`cms/page/${pageName}`);
    setIsApiCall(true);
    const response = await Get(url, apiHeader(accessToken));
    setIsApiCall(false);
    if (response !== undefined) {
      let data = response?.data?.data;

      setSec1Heading(data?.sec1Heading);
      setSec1Description(data?.sec1Description);

      setSec2Heading(data?.sec2Heading);
      setSec2Description(data?.sec2Description);

      setSelectedVideo(data?.sec4Video);

      setSec3Heading(data?.sec3Heading);
      setSec3Description(data?.sec3Description);

      setSec5Heading(data?.sec5Heading);

      setSec6Heading(data?.sec6Heading);

      setSec7Heading(data?.sec7Heading);

      setSec8Heading(data?.sec8Heading);
      setSec8Description(data?.sec8Description);

      //    Image
      const _section1Image1 = await urlToObject(
        `${imageUrl}${data?.sec1CoverImage}`
      );
      setSec1image1(_section1Image1);

      //    Image
      const _section1Image2 = await urlToObject(
        `${imageUrl}${data?.sec1Image}`
      );
      setSec1image2(_section1Image2);
    }
  };

  //   handleSelectVideo
  const handleSelectVideo = (event) => {
    setSelectedVideoFile(event.target.files[0]);
    let reader = new FileReader();

    reader.onload = (event) => {
      setSelectedVideo(event.target.result);

      var media = new Audio(reader.result);
      media.onloadedmetadata = function () {
        // setVideoDuration(millisToMinutesAndSeconds(media.duration * 1000));
      };
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  //  awsVideoUploader
  const awsVideoUploader = async (awsUrl, videoFile) => {
    // setIsAddEditApiCall(true);
    const result = await axios.put(awsUrl, videoFile, {
      headers: {
        "Content-Type": videoFile.type,
      },
      onUploadProgress: (data) => {
        setVideoProgress(Math.round((100 * data.loaded) / data.total));
      },
    });
    // setIsAddEditApiCall(false);
    if (result?.status !== 200) {
      toast.error(`Video upload failed`);
    }

    return result;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="add-project-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <h3>Home Page :</h3>
                </Col>
                <Col lg={12}>
                  <Row>
                    {/* Hero Section */}

                    <Col lg={12}>
                      <h4>Section1:</h4>
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec1Heading}
                        onChange={(e) => setSec1Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description:</h5>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter Description"
                        value={sec1Description}
                        onChange={(e) => setSec1Description(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>CoverImage:</h5>
                      <AddImageComponent
                        onSubmit={(data) => setSec1image1(data)}
                        initialState={sec1image1}
                        index={null}
                        item={sec1image1}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>section1-Image:</h5>
                      <AddImageComponent
                        onSubmit={(data) => setSec1image2(data)}
                        initialState={sec1image2}
                        index={null}
                        item={sec1image2}
                      />
                    </Col>

                    {/* Section2 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section2:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec2Heading}
                        onChange={(e) => setSec2Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description:</h5>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter  Description"
                        value={sec2Description}
                        onChange={(e) => setSec2Description(e.target.value)}
                      />
                    </Col>

                    {/* Section3 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section3:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec3Heading}
                        onChange={(e) => setSec3Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description:</h5>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter  Description"
                        value={sec3Description}
                        onChange={(e) => setSec3Description(e.target.value)}
                      />
                    </Col>

                    {/* Section4 */}

                    {/* {/ Video /} */}
                    <Col lg={12} className="mt-5">
                      <h4 className="mb-3">Section4:</h4>
                      <h5>Video:</h5>
                      <div class="form-group" className="seo__imagePicker">
                        <input
                          type="file"
                          accept="video/mp4,video/x-m4v,video/*"
                          name="uploadfile"
                          id="img"
                          style={{
                            display: "none",
                          }}
                          onChange={(event) => {
                            handleSelectVideo(event);
                          }}
                        />
                        <label for="exampleInputEmail1" className="mx-1">
                          Select Video
                        </label>
                        <label
                          for="img"
                          className="btn btn-primary btn-lg btn-block"
                          style={{
                            backgroundColor: green[500],
                          }}
                          onChange={(e) => {
                          }}
                        >
                          {selectedVideo ? "Change selection" : "Pick a Video"}
                        </label>
                      </div>
                    </Col>

                    {/* {/ Shoe Image /} */}
                    {selectedVideo && (
                      <div>
                        <CardMedia
                          component="video"
                          height="194"
                          controls
                          autoplay
                          src={
                            selectedVideoFile == null
                              ? `${selectedVideo}`
                              : selectedVideo
                          }
                        />
                      </div>
                    )}

                    {isloading && (
                      <div className="circular-loader mt-3">
                        {videoProgress > 1 && (
                          <CircularLoader val={videoProgress} />
                        )}
                        Please Wait...
                      </div>
                    )}
                    {/* {/ btn /}
                    {!isApiCall && videoProgress == 0 && (
                      <div className="container-login100-form-btn mt-4">
                        <div className="wrap-login100-form-btn">
                          <div className="login100-form-bgbtn"></div>
                          <button
                            disabled={isApiCall}
                            className="login100-form-btn"
                            onClick={btnClickHandler}
                          >
                            {isApiCall ? "Please Wait..." : "Submit"}
                          </button>
                        </div> 
                      </div> 
                    {/* )} */}

                    {/* Section5 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section5:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec5Heading}
                        onChange={(e) => setSec5Heading(e.target.value)}
                      />
                    </Col>

                    {/* Section6 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section6:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec6Heading}
                        onChange={(e) => setSec6Heading(e.target.value)}
                      />
                    </Col>

                    {/* Section7 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section7:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec7Heading}
                        onChange={(e) => setSec7Heading(e.target.value)}
                      />
                    </Col>

                    {/* Section8 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section8:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec8Heading}
                        onChange={(e) => setSec8Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description:</h5>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter  Description"
                        value={sec8Description}
                        onChange={(e) => setSec8Description(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <div className="save-btn">
                    <button
                      className="save-btn"
                      disabled={isApiCall}
                      onClick={() => handleSubmit()}
                    >
                      {isloading ? "please wait..." : "Save"}
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {isApiCall && <BodyLoader />}
    </>
  );
}

export default Home_cms;
