import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import AdminSideBar from "../components/AdminSideBar";
import { Get, Delete } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL } from "../Config/apiUrl";
import BodyLoader from "../components/BodyLoader";
import AreYouSureModal from "../components/AreYouSureModal";

function ContactUs() {
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isApicall, setIsApicall] = useState(false);
  const [tableData, setTableData] = useState([]);

  // getApiData
  const getApiDAta = async () => {
    setIsApicall(true);

    const url = BaseURL(`admin/contact-us`);
    const response = await Get(url, accessToken);
    setIsApicall(false);
    if (response !== undefined) {
      setTableData(response?.data?.data);
    }
  };

  //   handleDeleteApiCall
  const handleDeleteApiCall = async () => {
    const url = BaseURL(`admin/contact-us/${deleteItemId}`);
    setIsDeleteApiCall(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const filteredData = tableData.filter((e) => e?._id !== deleteItemId);
      setTableData(filteredData);
      setIsAreYouSureModalOpen(false);
      toast.success("Item deleted successfully.");
      setDeleteItemId(null);
    }
  };

  useEffect(() => {
    getApiDAta();
  }, []);

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <h3>Contact Enquiries</h3>
                </Col>
                <Col lg={12}>
                  <div className="invoice-Table">
                    <table>
                      <thead className="invoice-head">
                        <tr>
                          <th>No.</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Message</th>
                          <th colspan="2">Action</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item, index) => (
                          <tr className="invoice-row">
                            <td>{index + 1}</td>
                            <td>{item?.name}</td>
                            <td>{item?.email}</td>
                            <td>{item?.phone}</td>
                            <td>{item?.message}</td>
                            <td>
                              <div className="table-detail-btn delete-btn">
                                <button
                                  className="btn-detail m-0 c-r"
                                  onClick={() => {
                                    setIsAreYouSureModalOpen(true);
                                    setDeleteItemId(item?._id);
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {isApicall && <BodyLoader />}

      {isAreYouSureModalOpen && (
        <AreYouSureModal
          setOpen={setIsAreYouSureModalOpen}
          open={isAreYouSureModalOpen}
          handleYes={handleDeleteApiCall}
          title={"Are You Sure? Do you really want to delete this item?"}
          isLoading={isDeleteApiCall}
        />
      )}
    </>
  );
}

export default ContactUs;
