import React, { useState, useEffect } from "react";
import AdminSideBar from "../components/AdminSideBar";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Get,  Post } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, } from "../Config/apiUrl";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BodyLoader from "../components/BodyLoader";

function Footer_cms() {
  const location = useLocation();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const {
    state: { pageName, pageId },
  } = location;
  const accessToken = useSelector((state) => state.authReducer.access_token);

  //  section1 states

  const [description, setDescription] = useState("");
  // const [email ,setEmail] = useState("");
  // const [number1 , setNumber1] = useState("");
  // const [number2 , setNumber2] = useState("");
  const [copyright, setCopyright] = useState("");

  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName: pageName,

      description: description,
      // email:email,
      // number1:number1,
      // number2:number2,
      copyright: copyright,
    };

    const formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }

    //  post Api footer Page

    const url = BaseURL("cms/page/update");
    setIsloading(true);
    const response = await Post(url, formData, apiHeader(accessToken));
    setIsloading(false);

    if (response !== undefined) {
      toast.success("Services Page data updated successfully");
    }
  };

  // Get api footer page
  const getData = async () => {
    const url = BaseURL(`cms/page/${pageName}`);
    setIsApiCall(true);
    const response = await Get(url, apiHeader(accessToken));
    setIsApiCall(false);
    if (response !== undefined) {
      let data = response?.data?.data;
      setDescription(data?.description);
      // setEmail(data?.email);
      // setNumber1(data?.number1);
      // setNumber2(data?.number2);
      setCopyright(data?.copyright);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="add-project-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <h3>Footer :</h3>
                </Col>
                <Col lg={12}>
                  <Row>
                    {/* Hero Section */}

                    <Col lg={12}>
                      <h4>Section:</h4>
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description:</h5>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Col>
                    {/* <Col lg={12} className="mt-3">
                      <h5>Email:</h5>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Number 1:</h5>
                      <Form.Control
                        type="number"
                        placeholder="Enter Number"
                        value={number1}
                        onChange={(e)=>setNumber1(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Number 2:</h5>
                      <Form.Control
                        type="number"
                        placeholder="Enter Number"
                        value={number2}
                        onChange={(e)=>setNumber2(e.target.value)}
                      />
                    </Col> */}
                    <Col lg={12} className="mt-3">
                      <h5>Copyright:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter copyright line"
                        value={copyright}
                        onChange={(e) => setCopyright(e.target.value)}
                      />
                    </Col>
                    <Col lg={12}>
                      <div className="save-btn">
                        <button
                          className="save-btn"
                          disabled={isApiCall}
                          onClick={() => handleSubmit()}
                        >
                          {isloading ? "please wait..." : "Save"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {isApiCall && <BodyLoader />}
    </>
  );
}

export default Footer_cms;
