import React from "react";
import img from "../assets/images/box.png";
import c1Img from "../assets/images/c1.png";
import c2Img from "../assets/images/c2.png";
import c3Img from "../assets/images/c3.png";
import c4Img from "../assets/images/proposalImage.png";

const NullDataComponent = ({ text, noImage, sm, c1, c2, c3, c4 }) => {
  return (
    <div
      // style={{
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   flexDirection: "column",
      // }}
      className="nullData-box"
    >
      {!noImage && (
        <>
          {/* Small Image */}
          {sm && (
            <img
              src={img}
              style={{
                width: "50px",
                marginBottom: "10px",
              }}
            />
          )}
          {/* c1 */}
          {c1 && <img src={c1Img} className="nullData_image" />}
          {/* c2 */}
          {c2 && <img src={c2Img} className="nullData_image" />}
          {/* c3 */}
          {c3 && <img src={c3Img} className="nullData_image" />}
          {/* c4 */}
          {c4 && <img src={c4Img} className="nullData_image" />}
        </>
      )}
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "25px",
        }}
      >
        {text || "No data found."}
      </p>
    </div>
  );
};

export default NullDataComponent;
