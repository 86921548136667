import React, { useState, useEffect } from "react";
import { Container, Row, Col,  } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { useSelector } from "react-redux";
import {
  BaseURL,
  recordsLimit,
  formRegExReplacer,
  formRegEx,
  numberWithCommas,
} from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import AdminSideBar from "../components/AdminSideBar";
import { useHistory } from "react-router-dom";
import { currencies } from "../currencies";
import BodyLoader from "../components/BodyLoader";
import PaginationB from "../components/PaginationB";
import NullDataComponent from "./../components/NullDataComponent";

export default function AssignProjects() {
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const history = useHistory();
  const [assignedProjects, setAssignedProjects] = useState([]);
  const [isApiCall, setIsApiCall] = useState(false);

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [status, setStatus] = useState("all");
  const newArray = assignedProjects.filter(function (item) {
    if (status === "all") {
      return item;
    } else {
      return item?.projectStatus === status;
    }
  });

  // get data
  async function getData(_page) {
    const url = BaseURL(
      `admin/postedProjects?limit=${recordsLimit}&page=${_page}`
    );
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      setAssignedProjects(response?.data.data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
  }

  useEffect(() => {
    getData(page);
  }, [page]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <div className="Dashboardpage-invoice mt-0">
                    <h2>Posted Projects</h2>
                    <select
                      className="dropdown-style-x"
                      onChange={(e) => setStatus(e?.target?.value)}
                      value={status}
                    >
                      {[
                        "all",
                        "pending",
                        "underReview",
                        "inProgress",
                        "completed",
                      ].map((e) => (
                        <option key={e} value={e}>
                          {e.replace(formRegEx, formRegExReplacer)}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                {assignedProjects.length > 0 && (
                  <Col lg={12}>
                    <div className="invoice-Table">
                      <table>
                        <thead className="invoice-head">
                          <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Project Title</th>
                            <th>Budget</th>
                            <th>Status</th>
                            {/* <th>Completion</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {newArray.map((item, index) => {
                            let progressClass = "danger";
                            let progressValue =
                              (item.amountPayedToAdmin / item.amount) * 100;

                            if (progressValue <= 10) progressClass = "danger";
                            else if (progressValue > 10 && progressValue <= 30)
                              progressClass = "warning";
                            else if (progressValue > 30 && progressValue <= 80)
                              progressClass = "primary";
                            else if (progressValue > 80 && progressValue <= 100)
                              progressClass = "success";
                            else progressClass = "danger";
                            return (
                              <tr
                                key={item?.id}
                                className="invoice-row projectsection c-p"
                                onClick={() =>
                                  history.push({
                                    pathname: "/admin/detailPage",
                                    state: item._id,
                                    search: 'loc=2'
                                  })
                                }
                              >
                                <td>{index + 1}</td>
                                <td className="t-t-c">{item.title}</td>
                                <td className="t-t-c">{item?.postedBy?.name}</td>
                                <td>
                                  {
                                    currencies[item.currency.toUpperCase()]
                                      .symbol
                                  }
                                  {/* {numeral(item?.amount).format("0,00a")} */}
                                  {numberWithCommas(item?.amount)}
                                </td>
                                <td className={`t-t-c ${item.projectStatus}`}>
                                  {item.projectStatus.replace(
                                    formRegEx,
                                    formRegExReplacer
                                  )}
                                </td>

                                {/* <td>
                                  <div className="innerspan">
                                    <span className="value-box">
                                      {parseFloat(progressValue >= 100 ? 100 : progressValue ).toFixed(0)}%
                                    </span>
                                    <ProgressBar
                                      variant={progressClass}
                                      now={progressValue}
                                      className={progressClass}
                                    />
                                  </div>
                                </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      {totalCount > 1 && (
                        <PaginationB
                          page={page}
                          setPage={setPage}
                          totalCount={totalCount}
                        />
                      )}
                    </div>
                  </Col>
                )}
                <Col lg={12}>
                  {newArray.length === 0 && !isApiCall && (
                    <div className="p-d-null-data mt-25-vp">
                      <NullDataComponent
                        c4
                        text={isApiCall && "Please Wait..."}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              {assignedProjects.length === 0 && !isApiCall && (
                <div className="p-d-null-data">
                  <NullDataComponent c4 text={"No Project Posted yet."} />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {isApiCall && <BodyLoader />}
    </>
  );
}
