import React, { useState, useEffect } from "react";
import AdminSideBar from "../components/AdminSideBar";
import { Container, Row, Col, Form } from "react-bootstrap";
import AddImageComponent from "../components/AddImage";
import { Get, Post } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, imageUrl, urlToObject } from "../Config/apiUrl";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BodyLoader from "../components/BodyLoader";

function Services_cms() {
  const location = useLocation();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const {
    state: { pageName, pageId },
  } = location;
  const accessToken = useSelector((state) => state.authReducer.access_token);

  //  section1 states

  const [sec1Heading, setSec1Heading] = useState("");
  const [sec1image, setSec1image] = useState();

  //  section3 states

  const [sec3Heading, setSec3Heading] = useState("");
  const [sec3Description, setSec3Description] = useState("");

  //  section4 states

  const [sec4Heading, setSec4Heading] = useState("");

  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName: pageName,

      sec1Heading: sec1Heading,
      sec1CoverImage: sec1image,

      sec3Heading: sec3Heading,
      sec3Description: sec3Description,

      sec4Heading: sec4Heading,
    };

    const formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }

    //  post Api home Page

    const url = BaseURL("cms/page/update");
    setIsloading(true);
    const response = await Post(url, formData, apiHeader(accessToken));
    setIsloading(false);

    if (response !== undefined) {
      toast.success("Services Page data updated successfully");
    }
  };

  // Get api home page
  const getData = async () => {
    const url = BaseURL(`cms/page/${pageName}`);
    setIsApiCall(true);
    const response = await Get(url, apiHeader(accessToken));
    setIsApiCall(false);
    if (response !== undefined) {
      let data = response?.data?.data;

      setSec1Heading(data?.sec1Heading);

      setSec3Heading(data?.sec3Heading);
      setSec3Description(data?.sec3Description);

      setSec4Heading(data?.sec4Heading);

      //    Image
      const _section1Image1 = await urlToObject(
        `${imageUrl}${data?.sec1CoverImage}`
      );
      setSec1image(_section1Image1);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="add-project-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <h3>Services Page :</h3>
                </Col>
                <Col lg={12}>
                  <Row>
                    {/* Section1 */}

                    <Col lg={12}>
                      <h4>Section1:</h4>
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec1Heading}
                        onChange={(e) => setSec1Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={6} className="mt-3">
                      <h5>Section1 Image:</h5>
                      <AddImageComponent
                        onSubmit={(data) => setSec1image(data)}
                        initialState={sec1image}
                        index={null}
                        item={sec1image}
                      />
                    </Col>

                    {/* Section3 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section3:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec3Heading}
                        onChange={(e) => setSec3Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description:</h5>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter  Description"
                        value={sec3Description}
                        onChange={(e) => setSec3Description(e.target.value)}
                      />
                    </Col>

                    {/* Section4 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section4:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec4Heading}
                        onChange={(e) => setSec4Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={12}>
                      <div className="save-btn">
                        <button
                          className="save-btn"
                          disabled={isApiCall}
                          onClick={() => handleSubmit()}
                        >
                          {isloading ? "please wait..." : "Save"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {isApiCall && <BodyLoader />}
    </>
  );
}

export default Services_cms;
