// export const apiUrl = "https://sean-hoban-backend.herokuapp.com"; //live
export const apiUrl = "https://seanhoban-backend-dem-911d66b28d61.herokuapp.com"; //live demo
// export const apiUrl = "https://0656-103-244-178-85.eu.ngrok.io"; //local
export const imageUrl = `${apiUrl}/api/images/`;
export const pdfUrl = `${apiUrl}/api/pdf/`;

export const BaseURL = (link) => {
  return `${apiUrl}/api/v1/${link}`;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        // Accept: 'application/json',
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const firebaseVapidObject = {
  vapidKey: "",
};

export const stripe_public_key = "";
export const recordsLimit = 30;

// eur
// gbp
// currency

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";

export const urlToObject = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const file = new File([blob], "image.png", { type: blob.type });
  return file;
};

export const fallbackUser =
  "https://cdn-icons-png.flaticon.com/512/847/847969.png";

export function groupArrayOfObjects(list, key) {
  return list.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function numberWithCommas(x) {
  if (!x) {
    return x;
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export const getSpecificSearchParam = (param) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const loc = params.get(param);
  return loc;
};
