import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { FaFilePdf } from "react-icons/fa";
import { useSelector } from "react-redux";
import { BaseURL, numberWithCommas, pdfUrl } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { currencies } from "../currencies";
import AdminSideBar from "../components/AdminSideBar";
import { useHistory } from "react-router-dom";
import BodyLoader from "../components/BodyLoader";
import moment from "moment";

export default function Withdraw() {
  const history = useHistory();
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  const [widthdrawl, setWidthdrawl] = useState([]);
  const [isApicall, setIsApiCall] = useState(false);

  async function getData() {
    const url = BaseURL("admin/widthdrawlRequests");
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      const data = response?.data?.data;
      setWidthdrawl(data);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <div className="Dashboardpage-invoice">
                    <h2>Payments to Consultants-Outgoing</h2>
                    {/* <div className="Dashboardpage-invoice-btn">
                          <button>View More</button>
                        </div> */}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="invoice-Table">
                    <table>
                      <thead className="invoice-head">
                        <tr>
                          <th>No.</th>
                          <th>Consultant Name</th>
                          <th>Completion Date</th>
                          <th>Project Title</th>
                          <th>Milestone Title</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Invoice</th>
                        </tr>
                      </thead>
                      <tbody>
                        {widthdrawl?.map((item, index) => {
                          return (
                            <tr
                              className="invoice-row c-p"
                              key={index}
                              onClick={() =>
                                history.push({
                                  pathname: "/admin/detailPage",
                                  state: item?.projectDetail?.projectId,
                                  search: "loc=4",
                                })
                              }
                            >
                              <td>{index + 1}</td>
                              <td>{item?.projectDetail?.assignTo?.name}</td>
                              <td>{moment(item?.projectDetail?.timeline != "Invalid date" ?  item?.projectDetail?.timeline : moment().format("DD-MM-YYYY"), "DD-MM-YYYY").format("l")}</td>
                              <td className="t-t-c">
                                {item?.projectDetail?.projectTitle}
                              </td>
                              <td className="t-t-c">{item?.title}</td>
                              <td>
                                {
                                  currencies[
                                    item?.projectDetail?.projectCurrency?.toUpperCase()
                                  ].symbol
                                }
                                {/* {numeral(item?.amount).format("0,0")} */}
                                {numberWithCommas(item?.amount)}
                              </td>
                              <td className={`t-t-c ${item.status}`}>
                                {item.status}
                              </td>
                              <td
                                className="c-p"
                                onClick={(e) => {
                                  window.open(`${pdfUrl}${item?.invoice}`);
                                  {
                                    e.stopPropagation();
                                  }
                                }}
                              >
                                <span>Open PDF</span>
                                <FaFilePdf className="close-icon" />
                                <span></span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {isApicall && <BodyLoader />}
    </>
  );
}
