import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { BaseURL, recordsLimit } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import BodyLoader from "../components/BodyLoader";
import NotificationBox from "../components/NotificationBox";
import PaginationB from "../components/PaginationB";
import "../assets/styles/Notificationscreen.css";
import AdminSideBar from "../components/AdminSideBar";
import { toast } from "react-toastify";

const Notificationscreen = () => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [notificationData, setNotificationData] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const { access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  async function getData(_page) {
    const url = BaseURL(
      `notifications/all?limit=${recordsLimit}&page=${_page}`
    );
    setIsApiCall(true);
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      const data = response?.data?.data;
      setNotificationData(data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
    setIsApiCall(false);
  }

  useEffect(() => {
    getData(page);
  }, [page]);

  // markAllAsReadHandler
  const markAllAsReadHandler = async () => {
    const url = BaseURL("notifications/seen/all");

    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);

    if (response !== undefined) {
      setPage(1);
      await getData(1);
      toast.success("Notifications have been marked as read.");
    }
  };

  return (
    <div className="Dashboardpage-section">
      <Container>
        <Row>
          <Col md={3}>
            <AdminSideBar />
          </Col>
          <Col md={9}>
            <section className="Notification-section">
              <Container className="custom-container py">
                <div>
                  <div className="Notification-heading d-flex justify-content-between">
                    <h3>Notifications</h3>
                    <h3
                      className={"markasReadBtmn"}
                      onClick={() => markAllAsReadHandler()}
                    >
                      {isApiCall ? "Please Wait..." : "Mark All as Read"}
                    </h3>
                  </div>
                  {notificationData?.map((e, i) => (
                    <NotificationBox key={i} data={e} />
                  ))}

                  {totalCount > 1 && (
                    <PaginationB
                      page={page}
                      setPage={setPage}
                      totalCount={totalCount}
                    />
                  )}

                  {/* {notificationData.length === 0 && (
                    <div className="p-d-null-data mt-25-vp">
                      <NullDataComponent
                        c3
                        text={
                          isApiCall
                            ? "Please Wait..."
                            : "No. Notification Found."
                        }
                      />
                    </div>
                  )} */}

                  {isApiCall && <BodyLoader />}
                </div>
              </Container>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Notificationscreen;
