import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { toast } from "react-toastify";
import { formRegEx, formRegExReplacer, imageUrl } from "../Config/apiUrl";

// rating
import Rating from "@mui/material/Rating";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function AddOrUpdateCrudModal({
  showModal,
  closeModal,
  isLoading,
  data,
  handleSubmit,
}) {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [rating, setRating] = useState("");
  const [socialUrl, setSocialUrl] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  const [googlePlus, setGooglePlus] = useState("");
  const [sharableLink, setSharableLink] = useState("");
  const [secName, setSecName] = useState("");

  //   image states
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  //   cover states
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [selectedCoverImageFile, setSelectedCoverImageFile] = useState(null);

  useEffect(() => {
    if (data) {
      data?.title && setTitle(data?.title);
      data?.subTitle && setSubTitle(data?.subTitle);
      data?.description && setDescription(data?.description);
      data?.rating && setRating(data?.rating);
      data?.socialUrl && setRating(data?.rating);
      data?.linkedIn && setLinkedIn(data?.socialUrl);
      data?.twitter && setTwitter(data?.twitter);
      data?.googlePlus && setGooglePlus(data?.googlePlus);
      data?.sharableLink && setSharableLink(data?.sharableLink);
      data?.secName && setSecName(data?.secName);
      data?.image && setSelectedImage(data?.image);
      data?.coverImage && setSelectedCoverImage(data?.coverImage);
    }

    return () => {
      emptyStates();
    };
  }, [data]);

  // empty states
  const emptyStates = () => {
    setTitle("");
    setSubTitle("");
    setDescription("");
    setRating("");
    setSocialUrl("");
    setLinkedIn("");
    setTwitter("");
    setGooglePlus("");
    setSharableLink("");
    setSecName("");
    setSelectedImage(null);
    setSelectedCoverImage(null);
    setSelectedImageFile(null);
    setSelectedCoverImageFile(null);
  };

  //   handleSelectImage
  const handleSelectImage = (event, fileState, imagestate) => {
    fileState(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = (event) => {
      imagestate(event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  //   handleMakeData
  const handleMakeData = async () => {
    //   validate Image
    if (
      !data &&
      [
        "company",
        "onboarding-process",
        "our-services",
        "how-to-order",
        "testimonials",
        "our-latest-news",
        "social-icons",
        "greatest-asset-time",
        "quantities-for",
        "our-experts",
      ].includes(secName) &&
      !selectedImageFile
    ) {
      return toast.warn("Please select an image.");
    }

    // validate coverImage
    if (
      !data &&
      ["our-services"].includes(secName) &&
      !selectedCoverImageFile
    ) {
      return toast.warn("Please select a cover image.");
    }

    // add params conditionally
    const params = {
      ...([
        "onboarding-process",
        "our-services",
        "how-to-order",
        "our-latest-news",
        "quantities-for",
        "our-experts",
      ].includes(secName) && { title }),
      ...(["testimonials", "our-experts"].includes(secName) && { subTitle }),
      ...([
        "onboarding-process",
        "our-services",
        "how-to-order",
        "testimonials",
        "our-latest-news",
        "greatest-asset-time",
        "our-experts",
      ].includes(secName) && { description }),
      ...(["testimonials"].includes(secName) && { rating }),
      ...(["social-icons"].includes(secName) && { socialUrl }),
      ...(["our-experts"].includes(secName) && { linkedIn }),
      ...(["our-experts"].includes(secName) && { twitter }),
      ...(["our-experts"].includes(secName) && { googlePlus }),
      ...(["our-experts"].includes(secName) && { sharableLink }),
      secName,
      ...(selectedImageFile && { image: selectedImageFile }),
      ...(selectedCoverImageFile && { coverImage: selectedCoverImageFile }),
    };

    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required.`
        );
    await handleSubmit(params, emptyStates);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          closeModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {data ? "Edit" : "Add"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Title */}
            {[
              "onboarding-process",
              "our-services",
              "how-to-order",
              "our-latest-news",
              "quantities-for",
              "our-experts",
            ].includes(secName) && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-margin">Title</Form.Label>
                <Form.Control
                  className="input-box siginInputbox w-40"
                  type="text"
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
            )}

            {/* subtitle */}
            {["testimonials", "our-experts"].includes(secName) && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-margin">Sub Title</Form.Label>
                <Form.Control
                  className="input-box siginInputbox w-40"
                  type="text"
                  placeholder="Enter Sub title"
                  value={subTitle}
                  onChange={(e) => setSubTitle(e.target.value)}
                />
              </Form.Group>
            )}

            {/* description */}
            {[
              "onboarding-process",
              "our-services",
              "how-to-order",
              "testimonials",
              "our-latest-news",
              "greatest-asset-time",
              "our-experts",
            ].includes(secName) && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-margin">Description</Form.Label>
                <Form.Control
                  className="input-box siginInputbox"
                  type="text"
                  as={"textarea"}
                  placeholder="Enter Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            )}

            {/* twitter */}
            {["our-experts"].includes(secName) && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
                style={{
                  display: "contents",
                }}
              >
                <Form.Label className="label-margin">Twitter URL</Form.Label>
                <Form.Control
                  className="input-box siginInputbox w-40"
                  type="text"
                  placeholder="Enter twitter url"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </Form.Group>
            )}

            {/* googlePlus */}
            {["our-experts"].includes(secName) && (
              <Form.Group
                className="mb-3 mt-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-margin">GooglePlus URL</Form.Label>
                <Form.Control
                  className="input-box siginInputbox w-40"
                  type="text"
                  placeholder="Enter googlePlus url"
                  value={googlePlus}
                  onChange={(e) => setGooglePlus(e.target.value)}
                />
              </Form.Group>
            )}

            {/* sharableLink */}
            {["our-experts"].includes(secName) && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-margin">Sharable Link</Form.Label>
                <Form.Control
                  className="input-box siginInputbox w-40"
                  type="text"
                  placeholder="Enter sharable Link"
                  value={sharableLink}
                  onChange={(e) => setSharableLink(e.target.value)}
                />
              </Form.Group>
            )}

            {/* linkedIn */}
            {["our-experts"].includes(secName) && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-margin">LinkedIn URL</Form.Label>
                <Form.Control
                  className="input-box siginInputbox w-40"
                  type="text"
                  placeholder="Enter linkedIn url"
                  value={linkedIn}
                  onChange={(e) => setLinkedIn(e.target.value)}
                />
              </Form.Group>
            )}

            {/* socialUrl */}
            {["social-icons"].includes(secName) && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-margin">URL</Form.Label>
                <Form.Control
                  className="input-box siginInputbox w-40"
                  type="text"
                  placeholder="Enter url"
                  value={socialUrl}
                  onChange={(e) => setSocialUrl(e.target.value)}
                />
              </Form.Group>
            )}

            {/* Rating */}
            {["testimonials"].includes(secName) && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1 d-flex"
              >
                <Form.Label className="label-margin">Rating</Form.Label>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
              </Form.Group>
            )}

            {/* Image */}
            {[
              "company",
              "onboarding-process",
              "our-services",
              "how-to-order",
              "testimonials",
              "our-latest-news",
              "social-icons",
              "greatest-asset-time",
              "quantities-for",
              "our-experts",
            ].includes(secName) && (
              <>
                <div class="form-group" className="crud__ImagePicker">
                  <input
                    type="file"
                    name="uploadfile"
                    id="image"
                    style={{
                      display: "none",
                    }}
                    onChange={(event) => {
                      handleSelectImage(
                        event,
                        setSelectedImageFile,
                        setSelectedImage
                      );
                    }}
                  />
                  <label
                    for="exampleInputEmail1"
                    className="label-input100"
                    style={{ margin: "10px 0" }}
                  >
                    Select Image
                  </label>
                  <label
                    for="image"
                    className="btn btn-primary btn-lg btn-block mb-3"
                    style={{
                      backgroundColor: "green",
                    }}
                    onChange={(e) => {
                    }}
                  >
                    {selectedImage ? "Change selection" : "Pick an Image"}
                  </label>
                </div>

                {/* Shoe Image */}
                {selectedImage && (
                  <div>
                    <img
                      src={
                        selectedImageFile == null
                          ? `${imageUrl}${selectedImage}`
                          : selectedImage
                      }
                      className="crud-modal-image"
                    />
                  </div>
                )}
              </>
            )}

            {/* coverImage */}
            {["our-services"].includes(secName) && (
              <>
                <div class="form-group" className="crud__ImagePicker">
                  <input
                    type="file"
                    name="uploadfile"
                    id="coverImage"
                    style={{
                      display: "none",
                    }}
                    onChange={(event) => {
                      handleSelectImage(
                        event,
                        setSelectedCoverImageFile,
                        setSelectedCoverImage
                      );
                    }}
                  />
                  <label
                    for="exampleInputEmail1"
                    className="label-input100"
                    style={{ margin: "10px 0" }}
                  >
                    Select Cover Image
                  </label>
                  <label
                    for="coverImage"
                    className="btn btn-primary btn-lg btn-block mb-3"
                    style={{
                      backgroundColor: "green",
                    }}
                    onChange={(e) => {
                    }}
                  >
                    {selectedCoverImage
                      ? "Change selection"
                      : "Pick a Cover Image"}
                  </label>
                </div>

                {/* Shoe Image */}
                {selectedCoverImage && (
                  <div>
                    <img
                      src={
                        selectedCoverImageFile == null
                          ? `${imageUrl}${selectedCoverImage}`
                          : selectedCoverImage
                      }
                      className="crud-modal-image"
                    />
                  </div>
                )}
              </>
            )}

            {/* radio */}
            <FormControl component="fieldset" style={{ margin: "0 57px" }}>
              <RadioGroup
                row
                aria-label="status"
                name="controlled-radio-buttons-group"
                value={secName}
                onChange={(e) => setSecName(e.target.value)}
              >
                <FormControlLabel
                  value="company"
                  control={<Radio />}
                  label="Company"
                />
                <FormControlLabel
                  value="onboarding-process"
                  control={<Radio />}
                  label="Onboarding Process"
                />
                <FormControlLabel
                  value="our-services"
                  control={<Radio />}
                  label="Our Services"
                />
                <FormControlLabel
                  value="how-to-order"
                  control={<Radio />}
                  label="How To Order"
                />
                <FormControlLabel
                  value="testimonials"
                  control={<Radio />}
                  label="Testimonials"
                />
                <FormControlLabel
                  value="our-latest-news"
                  control={<Radio />}
                  label="Our Latest News"
                />
                <FormControlLabel
                  value="social-icons"
                  control={<Radio />}
                  label="Social Icons"
                />
                <FormControlLabel
                  value="greatest-asset-time"
                  control={<Radio />}
                  label="Greatest Asset Time"
                />
                <FormControlLabel
                  value="quantities-for"
                  control={<Radio />}
                  label="Quantities For"
                />
                <FormControlLabel
                  value="our-experts"
                  control={<Radio />}
                  label="Our Experts"
                />
              </RadioGroup>
            </FormControl>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-x">
            <button
              className="btn-detail"
              disabled={isLoading}
              onClick={handleMakeData}
            >
              {isLoading ? "Please Wait..." : `Submit`}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
