import React, { useState, useEffect } from "react";
import axios from "axios";
import { green } from "@mui/material/colors";
import { CardMedia } from "@mui/material";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AdminSideBar from "../components/AdminSideBar";
import AddImageComponent from "../components/AddImage";
import { Get, Post } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, imageUrl, urlToObject } from "../Config/apiUrl";
import CircularLoader from "../components/CircularLoader";
import BodyLoader from "../components/BodyLoader";

function About_cms() {
  const location = useLocation();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const {
    state: { pageName, pageId },
  } = location;
  const accessToken = useSelector((state) => state.authReducer.access_token);

  //  section1 states

  const [sec1Heading, setSec1Heading] = useState("");
  const [sec1image, setSec1image] = useState();

  //  section2 states

  const [sec2Description1, setSec2Description1] = useState("");
  const [sec2Heading, setSec2Heading] = useState("");
  const [sec2Description2, setSec2Description2] = useState("");
  const [sec2image, setSec2image] = useState();

  //  section3 states

  const [sec3Heading, setSec3Heading] = useState("");
  const [sec3Description, setSec3Description] = useState("");
  // VIDEO
  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedVideoFile, setSelectedVideoFile] = useState(null);

  //  section4 states

  const [sec4Heading, setSec4Heading] = useState("");
  const [sec4Description, setSec4Description] = useState("");

  const [videoProgress, setVideoProgress] = useState("");

  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName: pageName,

      sec1Heading: sec1Heading,
      sec1CoverImage: sec1image,

      sec2Description1: sec2Description1,
      sec2Heading: sec2Heading,
      sec2Description2: sec2Description2,
      sec2CoverImage: sec2image,

      sec3Heading: sec3Heading,
      sec3Description: sec3Description,

      sec4Heading: sec4Heading,
      sec4Description: sec4Description,
    };

    const formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }

    if (selectedVideoFile) {
      formData.sec3Video = selectedVideoFile;
      formData.append("isVideo", true);
    } else {
      formData.append("isVideo", false);
    }

    //  post Api About Page

    const url = BaseURL("cms/page/update");
    setIsloading(true);
    const response = await Post(url, formData, apiHeader(accessToken));
    selectedVideoFile &&
      (await awsVideoUploader(response?.data?.url, selectedVideoFile));
    setIsloading(false);

    if (response !== undefined) {
      toast.success("About Us Page data updated successfully");
    }
  };

  // Get api About page
  const getData = async () => {
    const url = BaseURL(`cms/page/${pageName}`);
    setIsApiCall(true);
    const response = await Get(url, apiHeader(accessToken));
    setIsApiCall(false);
    if (response !== undefined) {
      let data = response?.data?.data;

      setSec1Heading(data?.sec1Heading);

      setSec2Description1(data?.sec2Description1);
      setSec2Heading(data?.sec2Heading);
      setSec2Description2(data?.sec2Description2);

      setSec3Heading(data?.sec3Heading);
      setSec3Description(data?.sec3Description);
      setSelectedVideo(data?.sec3Video);

      setSec4Heading(data?.sec4Heading);
      setSec4Description(data?.sec4Description);

      //  cover  Image
      const _section1Image1 = await urlToObject(
        `${imageUrl}${data?.sec1CoverImage}`
      );
      setSec1image(_section1Image1);

      //    Image
      const _section2Image1 = await urlToObject(
        `${imageUrl}${data?.sec2CoverImage}`
      );
      setSec2image(_section2Image1);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleSelectVideo = (event) => {
    setSelectedVideoFile(event.target.files[0]);
    let reader = new FileReader();

    reader.onload = (event) => {
      setSelectedVideo(event.target.result);

      var media = new Audio(reader.result);
      media.onloadedmetadata = function () {
        // setVideoDuration(millisToMinutesAndSeconds(media.duration * 1000));
      };
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  //  awsVideoUploader
  const awsVideoUploader = async (awsUrl, videoFile) => {
    // setIsAddEditApiCall(true);
    const result = await axios.put(awsUrl, videoFile, {
      headers: {
        "Content-Type": videoFile.type,
      },
      onUploadProgress: (data) => {
        setVideoProgress(Math.round((100 * data.loaded) / data.total));
      },
    });
    // setIsAddEditApiCall(false);
    if (result?.status !== 200) {
      toast.error(`Video upload failed`);
    }

    return result;
  };

  return (
    <>
      <section className="add-project-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <h3>About Page :</h3>
                </Col>
                <Col lg={12}>
                  <Row>
                    {/* Hero Section */}

                    <Col lg={12}>
                      <h4>Section1:</h4>
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec1Heading}
                        onChange={(e) => setSec1Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={6} className="mt-3">
                      <h5>Image:</h5>
                      <AddImageComponent
                        onSubmit={(data) => setSec1image(data)}
                        initialState={sec1image}
                        index={null}
                        item={sec1image}
                      />
                    </Col>

                    {/* Section2 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section2:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Description1:</h5>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter  Description"
                        value={sec2Description1}
                        onChange={(e) => setSec2Description1(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec2Heading}
                        onChange={(e) => setSec2Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description2:</h5>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter  Description"
                        value={sec2Description2}
                        onChange={(e) => setSec2Description2(e.target.value)}
                      />
                    </Col>
                    <Col lg={6} className="mt-3">
                      <h5>Image:</h5>
                      <AddImageComponent
                        onSubmit={(data) => setSec2image(data)}
                        initialState={sec2image}
                        index={null}
                        item={sec2image}
                      />
                    </Col>

                    {/* Section3 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section3:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec3Heading}
                        onChange={(e) => setSec3Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description:</h5>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter  Description"
                        value={sec3Description}
                        onChange={(e) => setSec3Description(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Video:</h5>
                      {/* {/ Video /} */}
                      <div class="form-group" className="seo__imagePicker">
                        <input
                          type="file"
                          accept="video/mp4,video/x-m4v,video/*"
                          name="uploadfile"
                          id="img"
                          style={{
                            display: "none",
                          }}
                          onChange={(event) => {
                            handleSelectVideo(event);
                          }}
                        />
                        <label for="exampleInputEmail1" className="mx-1">
                          Select Video
                        </label>
                        <label
                          for="img"
                          className="btn btn-primary btn-lg btn-block"
                          style={{
                            backgroundColor: green[500],
                          }}
                          onChange={(e) => {
                          }}
                        >
                          {selectedVideo ? "Change selection" : "Pick a Video"}
                        </label>
                      </div>

                      {/* {/ Shoe Image /} */}
                      {selectedVideo && (
                        <div>
                          <CardMedia
                            component="video"
                            height="194"
                            controls
                            autoplay
                            src={
                              selectedVideoFile == null
                                ? `${selectedVideo}`
                                : selectedVideo
                            }
                          />
                        </div>
                      )}

                      {isloading && (
                        <div className="circular-loader mt-3">
                          {videoProgress > 1 && (
                            <CircularLoader val={videoProgress} />
                          )}
                          Please Wait...
                        </div>
                      )}
                      {/* {/ btn /}
                    {!isApiCall && videoProgress == 0 && (
                      <div className="container-login100-form-btn mt-4">
                        <div className="wrap-login100-form-btn">
                          <div className="login100-form-bgbtn"></div>
                          <button
                            disabled={isApiCall}
                            className="login100-form-btn"
                            onClick={btnClickHandler}
                          >
                            {isApiCall ? "Please Wait..." : "Submit"}
                          </button>
                        </div> 
                      </div> 
                    {/* )} */}
                    </Col>

                    {/* Section4 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section4:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec4Heading}
                        onChange={(e) => setSec4Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description:</h5>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter  Description"
                        value={sec4Description}
                        onChange={(e) => setSec4Description(e.target.value)}
                      />
                    </Col>
                    <Col lg={12}>
                      <div className="save-btn">
                        <button
                          className="save-btn"
                          disabled={isApiCall}
                          onClick={() => handleSubmit()}
                        >
                          {isloading ? "please wait..." : "Save"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {isApiCall && <BodyLoader />}
    </>
  );
}

export default About_cms;
