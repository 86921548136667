import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Modal, Form } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { imageUrl } from "../Config/apiUrl";

export default function AddOrUpdateCrudModal({
  showModal,
  closeModal,
  isLoading,
  data,
  handleSubmit,
}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  //   image states
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  useEffect(() => {
    if (data) {
      data?.title && setTitle(data?.title);
      data?.description && setDescription(data?.description);
      data?.image && setSelectedImage(data?.image);
    }

    return () => {
      emptyStates();
    };
  }, [data]);

  // empty states
  const emptyStates = () => {
    setTitle("");
    setDescription("");
    setSelectedImageFile(null);
  };

  //   handleSelectImage
  const handleSelectImage = (event, fileState, imagestate) => {
    fileState(event.target.files[0]);
    let reader = new FileReader();
    reader.onload = (event) => {
      imagestate(event.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          closeModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {data ? "Edit" : "Add"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="label-margin">Title</Form.Label>
              <Form.Control
                className="input-box siginInputbox w-40"
                type="text"
                placeholder="Enter Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="label-margin">Description</Form.Label>
              <div className="descriptionQill">
                <ReactQuill
                  className=""
                  value={description}
                  onChange={(e) => setDescription(e)}
                />
              </div>
            </Form.Group>
          </Form>
          <div class="form-group mt-5 mb-5" className="crud__ImagePicker">
            <input
              type="file"
              name="uploadfile"
              id="image"
              style={{
                display: "none",
              }}
              onChange={(event) => {
                handleSelectImage(
                  event,
                  setSelectedImageFile,
                  setSelectedImage
                );
              }}
            />
            <label
              for="exampleInputEmail1"
              className="label-input100"
              style={{ margin: "10px 0" }}
            >
              Select Image
            </label>
            <label
              for="image"
              className="btn btn-primary btn-lg btn-block mb-3"
              style={{
                backgroundColor: "green",
              }}
              onChange={(e) => {
              }}
            >
              {selectedImage ? "Change selection" : "Pick an Image"}
            </label>
          </div>
          {selectedImage && (
            <div>
              <img
                src={
                  selectedImageFile == null
                    ? `${imageUrl}${selectedImage}`
                    : selectedImage
                }
                className="crud-modal-image"
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-x">
            <button
              className="btn-detail"
              disabled={isLoading}
              onClick={() =>
                handleSubmit({
                  title,
                  description,
                  image: selectedImageFile ? selectedImageFile : selectedImage,
                })
              }
            >
              {isLoading ? "Please Wait..." : `Submit`}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
