import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
const moment = require("moment");

const data = [
  {
    name: "sat",
    amt: 1000,
  },
  {
    name: "sun",

    amt: 1500,
  },
  {
    name: "mon",

    amt: 1000,
  },
  {
    name: "tue",

    amt: 2000,
  },
  {
    name: "thu",

    amt: 1500,
  },
  {
    name: "fri",

    amt: 1800,
  },
];

export default function chart(data) {
  let graphData = data.data;
  let graphPeriod = data.graphPeriod;
  let queryDates = data.queryDates;

  var dates = [];
  let monthStart,
    monthEnd,
    monthEndDate,
    monthStartDate,
    weekStart,
    weekEnd,
    weekEndDate;
  if (graphPeriod === "Last Week" || graphPeriod === "This Week") {
    weekStart = moment(queryDates?.start).format("D");
    weekEnd = moment(queryDates?.end).format("D");
    weekEndDate = moment(queryDates?.end).format();

    for (var i = weekStart; i <= weekEnd; i++) {
      let date = moment(weekEndDate)
        .subtract(weekEnd - i, "days")
        .format("YYYY-MM-DD");
      let amount = graphData?.find((e) => {
        return e?._id == date;
      })?.amount;
      dates.push({
        name: moment(date).format("ddd"),
        amount: amount || 0,
      });
    }
  }

  // last month
  if (graphPeriod === "Last Month") {
    monthStart = moment().startOf("month").add(-1, "months").format("D");
    monthEnd = moment().endOf("month").add(-1, "months").format("D");
    monthEndDate = moment().endOf("month").add(-1, "months").format();
    for (var i = monthStart; i <= monthEnd; i++) {
      let date = moment(monthEndDate)
        .subtract(monthEnd - i, "days")
        .format("YYYY-MM-DD");
      let amount = graphData?.find((e) => {
        return e?._id == date;
      })?.amount;
      dates.push({
        name: moment(date).format("D"),
        amount: amount || 0,
      });
    }
  }

  // Last 3 months, 6 and 12
  if (
    graphPeriod === "Last Three Months" ||
    graphPeriod === "Last Six Months" ||
    graphPeriod === "Last Twelve Months"
  ) {
    monthStartDate = moment(queryDates?.start).format();
    let currentMonth = moment(monthStartDate).format("MMM");
    let monthArray = [];

    const dateDifference = moment(queryDates?.end).diff(
      moment(queryDates?.start),
      "days"
    );

    for (var i = 0; i <= dateDifference; i++) {
      let date = moment(monthStartDate).add(i, "days").format("YYYY-MM-DD");
      let dateMonth = moment(date).format("MMM");

      let amount = graphData?.find((e) => {
        return e?._id == date;
      })?.amount;

      if (currentMonth !== dateMonth) {
        dates.push({
          name: currentMonth,
          amount: monthArray?.reduce((e, value) => e + value?.amount, 0) || 0,
        });
        monthArray = [];
        currentMonth = dateMonth;
        monthArray.push({
          name: moment(date).format(""),
          amount: amount || 0,
        });
      } else if (dateDifference == i) {
        monthArray.push({
          name: moment(date).format(""),
          amount: amount || 0,
        });
        dates.push({
          name: currentMonth,
          amount: monthArray?.reduce((e, value) => e + value?.amount, 0) || 0,
        });
        monthArray = [];
        currentMonth = dateMonth;
      } else {
        monthArray.push({
          name: moment(date).format(""),
          amount: amount || 0,
        });
      }
    }
  }

  console.log("dates ", dates);

  return (
    <ResponsiveContainer width="100%" aspect={2}>
      <AreaChart
        data={dates}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="coloramt" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#1CB735" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#1CB735" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" interval={"preserveStartEnd"} />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="amount"
          activeDot={{ r: 5 }}
          stroke="#1CB735"
          fillOpacity={1}
          fill="url(#coloramt)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
