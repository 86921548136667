import React, { useState, useEffect, useRef } from "react";
import "../assets/styles/chatScreen.css";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import inbox_logo from "../assets/images/inbox_logo.png";
import { RiSendPlaneFill } from "react-icons/ri";
import { MdAttachFile } from "react-icons/md";
import AdminSideBar from "../components/AdminSideBar";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Get } from "../Axios/AxiosFunctions";
import { apiUrl, BaseURL, recordsLimit } from "../Config/apiUrl";
import { FileUploader } from "react-drag-drop-files";
import file_img from "../assets/images/file-img.png";
import { MdOutlineExitToApp } from "react-icons/md";

import { v4 as uuidv4 } from "uuid";
import { io } from "socket.io-client";
import moment from "moment";
import { IconButton } from "@mui/material";

const ChatScreen = () => {
  const inputRef = useRef();
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const historyObj = useLocation()?.state;
  const socket = useRef(null);
  const [messageText, setMessageText] = useState("");
  const [roomData, setRoomData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [otherUser, setOtherUser] = useState(null);

  // do we have file
  const [doWeHaveFile, setDoWeHaveFile] = useState(false);

  const userId = user?._id;
  const role = user?.role;
  const userName = historyObj?.userName;

  // scrollToBottom chat container
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  // get all msgs
  async function getMessage(oldData, pageNo) {
    const url = `chat/get-messages?projectId=${historyObj?.projectId}&userId=${historyObj?.userId}&proposalId=${historyObj?.proposalId}&roomId=${roomData?.id}&limit=${recordsLimit}&page=${pageNo}`;
    setLoading(true);
    const response = await Get(BaseURL(url), accessToken);
    if (response !== undefined) {
      getChartWithUser(response?.data?.room);
      setPageNumber((pre) => pre + 1);
      if (oldData.length == 0) {
        let allMessages = response?.data?.data.reverse();
        setMessages(allMessages);
        setRoomData(response?.data?.room);
      } else {
        let newArray = oldData.slice();
        let allMessages = response?.data?.data.reverse();
        allMessages = allMessages.concat(newArray);
        setMessages(allMessages);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    getMessage([], 1);
  }, []);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  // on text msg send
  async function onSend() {
    if (messageText === "") {
      return toast.error("Please write the message", {
        position: "top-center",
      });
    }

    let newMsg = {
      _id: uuidv4(),
      text: messageText,
      createdAt: moment().format(),
      user: {
        _id: userId,
        name: user?.name,
      },
    };
    setMessages((prev) => [...prev, newMsg]);
    let msgTo = userId == roomData?.user1 ? roomData?.user2 : roomData?.user1;

    socket.current.emit(
      "msg",
      newMsg,
      msgTo,
      roomData?.id,
      roomData?.projectId
    );
    setMessageText("");
    scrollToBottom();
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  //  Socket Connection
  useEffect(() => {
    socket.current = io(apiUrl, { transports: ["websocket"] });
    socket.current.emit("join", userId);
  }, []);

  useEffect(() => {
    if (roomData !== null) {
      socket.current.emit("chatJoin", historyObj?.userId, roomData?.id);
      socket.current.on("msg", (msg, socketRoomId) => {
        if (roomData?.id === socketRoomId && msg?.user?._id !== userId) {
          setMessages((prev) => [...prev, msg]);
          socket.current.emit("mark-as-read", roomData?.id, role);
        }
      });
    }
  }, [roomData]);

  // handle file send
  const uploadImage = async (file) => {
    let reader = new FileReader();
    if (file?.size / 1024 / 1024 > 1) {
      return toast.info("😥 Max file size limit is 1MB", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }

    reader.onload = async (readerEvent) => {
      const imageUrl = readerEvent.target.result;

      let messageToSend = {
        message: {
          _id: uuidv4(),
          text: "",
          createdAt: moment().format(),
          src: file,
          type: "media",
          mediaType: file?.type?.split("/")[1],

          user: {
            _id: userId,
            name: user?.name,
          },
        },
        roomId: roomData?.id,
        to: historyObj?.userId,
        from: userId,
        isReadMessge: 0,
      };

      let msgToAppend = { ...messageToSend?.message, src: imageUrl };

      setMessages((prevState) => [...prevState, msgToAppend]);
      socket.current.emit(
        "image",
        messageToSend,
        historyObj?.userId,
        userId,
        roomData?.id,
        role,
        file.type.split("/")[1]
      );
    };

    reader?.readAsDataURL(file);
  };

  // handle open correct url
  const handleOpenCorrectUrl = (src) => {
    let data = src;
    let w = window.open("about:blank");
    let image = new Image();
    image.src = data;
    setTimeout(function () {
      w.document.write(image.outerHTML);
    }, 0);
  };

  // validate Path
  const validatePath = (src, mediaType) => {
    if (
      src.startsWith("data:") &&
      ["png", "jpeg", "jpg", "gif"].includes(mediaType)
    ) {
      handleOpenCorrectUrl(src);
    } else {
      window.open(src, "_blank");
    }
  };

  // calculateImagePath
  const calculateImagePath = (src, mediaType) => {
    if (["png", "jpeg", "jpg", "gif"].includes(mediaType)) {
      return src;
    } else {
      return file_img;
    }
  };

  const getChartWithUser = async (room) => {
    const withUserId = user?._id == room?.user1 ? room?.user2 : room?.user1;
    const url = BaseURL(`users/specific/user/${withUserId}`);
    const response = await Get(url, accessToken, false);
    if (response !== undefined) {
      setOtherUser(response?.data?.data?.user);
    }
  };

  const history = useHistory();

  return (
    <>
      <style>
        {`
      
      label[for="file"] {
        width: 98%;
        bottom: 10px;
        left: 10px;
        right: 10px;
      }
      `}
      </style>
      {/* <Header lg /> */}
      <section className="chatMessage_sect Dashboardpage-section">
        <IconButton
          className="exitBtn"
          title="Exit Chat"
          onClick={() => history.goBack()}
        >
          <MdOutlineExitToApp />
        </IconButton>
        <Container className="custom-container py ">
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9} md={12}>
              <Row className="flex-wrap msg_heading">
                <Row>
                  <Col lg={12} md={12}>
                    <h2>Messages</h2>
                  </Col>
                </Row>
                <Col lg={12} md={12}>
                  <Row>
                    <Col md={12}>
                      <div className="logo_tobar">
                        <div className="logo_main">
                          <div className="logo_img">
                            <img src={inbox_logo} alt="" />
                          </div>
                          <div className="logo_text">
                            <h6>Demo</h6>
                            <p className="xsmall t-t-c mt-0">
                              {otherUser?.name}
                            </p>
                          </div>
                        </div>
                        <FileUploader
                        classes={"drop_area drop_zone"}
                          multiple={false}
                          handleChange={(file) => {
                            uploadImage(file);
                          }}
disabled
                          name="file"
                          types={[
                            "JPEG",
                            "PNG",
                            "GIF",
                            "JPG",
                            "PDF",
                            "DOC",
                            "DOCX",
                            "XLS",
                            "XLSX",
                            "PPT",
                            "PPTX",
                            "ZIP",
                          ]}
                          onDraggingStateChange={(dragging) =>
                            setDoWeHaveFile(dragging)
                          }
                          classes={doWeHaveFile && `we-have-file`}
                        >
                          <Row className="scrollMsgs">
                            <div
                              className={`chats-container ${
                                doWeHaveFile && "hide-chat-container"
                              }`}
                            >
                              {loading && messages.length == 0 ? (
                                <div className="loaderContainer">
                                  <Spinner animation="grow" variant="light" />
                                  <span>please wait</span>
                                </div>
                              ) : (
                                <>
                                  <div className="chatLoadMoreBtnContainer">
                                    {messages.length >= recordsLimit &&
                                      messages.length >=
                                        recordsLimit * (pageNumber - 1) && (
                                        <Button
                                          variant="light"
                                          onClick={() => {
                                            getMessage(messages, pageNumber);
                                          }}
                                          disabled={loading}
                                        >
                                          {loading ? "Loading..." : "Load More"}
                                        </Button>
                                      )}
                                  </div>
                                  {messages.map((items, index) => (
                                    <div key={`messageContainer ${index}`}>
                                      <Col md={12} className="messageContainer">
                                        {items?.user?._id == userId ? (
                                          <div className="outgoing ms-auto">
                                            <div
                                              className="inbox_chat outgoing_chat"
                                              style={{
                                                background: "#1CB735",
                                              }}
                                            >
                                              {items?.text?.length > 0 && (
                                                <p className="small">
                                                  {items?.text}
                                                </p>
                                              )}

                                              {/* media */}
                                              {items?.type == "media" && (
                                                <img
                                                  src={calculateImagePath(
                                                    items?.src,
                                                    items?.mediaType
                                                  )}
                                                  className="w-50-image"
                                                  onClick={() =>
                                                    validatePath(
                                                      items?.src,
                                                      items?.mediaType
                                                    )
                                                  }
                                                />
                                              )}
                                            </div>
                                            <span className="msg_timing">
                                              {moment().diff(
                                                items?.createdAt,
                                                "days"
                                              ) == 0
                                                ? `Today ${moment(
                                                    items?.createdAt
                                                  ).format("hh:mm A")}`
                                                : moment().diff(
                                                    items?.createdAt,
                                                    "days"
                                                  ) > 0 &&
                                                  moment().diff(
                                                    items?.createdAt,
                                                    "days"
                                                  ) < 7
                                                ? moment(
                                                    items?.createdAt
                                                  ).format("ddd hh:mm A")
                                                : moment(
                                                    items?.createdAt
                                                  ).format("DD-MM-YY hh:mm A")}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="incoming me-auto">
                                            <div
                                              className="inbox_chat"
                                              style={{
                                                background: "#FFBF00",
                                              }}
                                            >
                                              {items?.text?.length > 0 && (
                                                <p
                                                  className="small"
                                                  style={{
                                                    color: "#000",
                                                  }}
                                                >
                                                  {items?.text}
                                                </p>
                                              )}
                                              {/* media */}
                                              {items?.type == "media" && (
                                                <img
                                                  src={calculateImagePath(
                                                    items?.src,
                                                    items?.mediaType
                                                  )}
                                                  className="w-50-image"
                                                  onClick={() =>
                                                    validatePath(
                                                      items?.src,
                                                      items?.mediaType
                                                    )
                                                  }
                                                />
                                              )}
                                            </div>
                                            <span className="msg_timing">
                                              {moment().diff(
                                                items?.createdAt,
                                                "days"
                                              ) == 0
                                                ? `Today ${moment(
                                                    items?.createdAt
                                                  ).format("hh:mm A")}`
                                                : moment().diff(
                                                    items?.createdAt,
                                                    "days"
                                                  ) > 0 &&
                                                  moment().diff(
                                                    items?.createdAt,
                                                    "days"
                                                  ) < 7
                                                ? moment(
                                                    items?.createdAt
                                                  ).format("ddd hh:mm A")
                                                : moment(
                                                    items?.createdAt
                                                  ).format("DD-MM-YY hh:mm A")}
                                            </span>
                                          </div>
                                        )}
                                      </Col>
                                    </div>
                                  ))}
                                  <div ref={messagesEndRef} />
                                </>
                              )}
                            </div>
                          </Row>
                        </FileUploader>

                        <Col md={12}>
                          <div
                            className={`send_msg_field ${
                              doWeHaveFile && "send_msg_field_2"
                            }`}
                          >
                            <div className="send_msg">
                              <div className="btn-file">
                                <MdAttachFile />
                                <input
                                  type="file"
                                  // accept="image/png, image/jpeg, image/gif"
                                  onChange={(e) =>
                                    uploadImage(e.target.files[0])
                                  }
                                />
                              </div>
                              <input
                                ref={inputRef}
                                type="text"
                                name="send message"
                                value={messageText}
                                onChange={(e) => setMessageText(e.target.value)}
                                placeholder="Type message here"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    onSend();
                                  }
                                }}
                              />

                              <button
                                onClick={(e) => {
                                  // event priority handler
                                  if (!e) {
                                    e = window.event;
                                  }
                                  e.cancelBubble = true;
                                  e.stopPropagation();
                                  onSend();
                                }}
                              >
                                <RiSendPlaneFill />
                              </button>
                            </div>

                            {/* <div className="send_msg">
                              <input
                                type="text"
                                name="send message"
                                value={messageText}
                                onChange={(e) => setMessageText(e.target.value)}
                                placeholder="Type message here"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    onSend();
                                  }
                                }}
                              />
                              <button onClick={() => onSend()}>
                                <RiSendPlaneFill />
                              </button>
                            </div> */}
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ChatScreen;
