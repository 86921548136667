import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Get, Post } from "../Axios/AxiosFunctions";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import "../assets/styles/Admindashboard.css";
import AdminSideBar from "../components/AdminSideBar";
import BodyLoader from "../components/BodyLoader";
import ConsultantDetailModal from "../components/ConsultantDetailModal";
import PaginationB from "../components/PaginationB";
var numeral = require("numeral");
var moment = require("moment");

export default function Contractors() {
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  const [Workers, setWorkers] = useState([]);
  const [isApicall, setIsApicall] = useState(false);

  const [isAccepting, setIsAccepting] = useState(null);
  const [isRejecting, setIsRejecting] = useState(null);
  const [isBlocking, setIsBlocking] = useState(null);

  const [contractorDetail, setContractorDetail] = useState({
    isOpen: false,
    data: null,
  });

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  async function getData(_page) {
    const url = BaseURL(
      `admin/contractors?limit=${recordsLimit}&page=${_page}`
    );
    setIsApicall(true);
    const response = await Get(url, accessToken);
    setIsApicall(false);
    if (response !== undefined) {
      setWorkers(response?.data.data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
  }

  useEffect(() => {
    getData(page);
  }, [page]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);


  // changeSttaus
  const handleStatusChange = async (id, status) => {
    if ([isAccepting, isRejecting, isBlocking].some((e) => e != null)) return;

    const url = BaseURL(
      `admin/update-freelancer-status?freelancerId=${id}&status=${status}`
    );
    status == "accepted" && setIsAccepting(id);
    status == "rejected" && setIsRejecting(id);
    status == "blocked" && setIsBlocking(id);
    const response = await Post(url, {}, apiHeader(accessToken));
    status == "accepted" && setIsAccepting(null);
    status == "rejected" && setIsRejecting(null);
    status == "blocked" && setIsBlocking(null);

    if (response !== undefined) {
      const freelancersCopy = [...Workers];
      const index = freelancersCopy.findIndex(
        (freelancer) => freelancer._id === id
      );
      freelancersCopy[index].status = status;
      setWorkers(freelancersCopy);
      toast.success(`Worker ${status} successfully.`);
    }
  };

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <div className="Dashboardpage-invoice mt-0">
                    <h2>Registered Consultants</h2>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="invoice-Table invoive-table-x">
                    <table>
                      <thead className="invoice-head">
                        <tr>
                          <th>No.</th>
                          <th>Name</th>
                          <th>Email</th>
                          {/* <th>Contact No.</th>
                          <th>Last Login</th>
                          <th>Status</th>
                          <th>Created On</th> */}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Workers?.map((item, index) => {
                          return (
                            <tr className="invoice-row projectsection invoive-tr-x">
                              <td>{index + 1}</td>
                              <td className="t-t-c">{item?.name}</td>
                              <td>{item.email}</td>
                              {/* <td>{item?.contactNo}</td>
                              <td>
                                {moment(item?.lastLogin).format("YYYY-MM-DD")}
                              </td>
                              <td className="t-t-c">{item?.status}</td>
                              <td>
                                {moment(item?.createdAt).format("YYYY-MM-DD")}
                              </td> */}
                              <td>
                                <div className="d-flex">
                                  <button
                                    className="btn-detail m-0 me-2 btn_x w-113"
                                    onClick={() =>
                                      setContractorDetail({
                                        isOpen: true,
                                        data: item,
                                      })
                                    }
                                  >
                                    View Details
                                  </button>
                                  {/* approve */}
                                  {["pending", "blocked", "rejected"].includes(
                                    item?.status
                                  ) && (
                                    <button
                                      className="btn-detail m-0 me-2 btn_x"
                                      onClick={() =>
                                        handleStatusChange(
                                          item?._id,
                                          "accepted"
                                        )
                                      }
                                      // disabled={
                                      //   ![null, undefined].includes([
                                      //     isAccepting,
                                      //     isRejecting,
                                      //     isBlocking,
                                      //   ])
                                      // }
                                    >
                                      {isAccepting === item?._id
                                        ? "Approving"
                                        : "Approve"}
                                    </button>
                                  )}

                                  {/* Reject */}
                                  {["accepted", "pending"].includes(
                                    item?.status
                                  ) && (
                                    <button
                                      className="btn-detail m-0 c-r me-2 btn_x"
                                      onClick={() =>
                                        handleStatusChange(
                                          item?._id,
                                          "rejected"
                                        )
                                      }
                                      // disabled={
                                      //   isAccepting || isRejecting || isBlocking
                                      // }
                                    >
                                      {isRejecting === item?._id
                                        ? "Rejecting"
                                        : "Reject"}
                                    </button>
                                  )}

                                  {/* Block */}
                                  {["accepted"].includes(item?.status) && (
                                    <button
                                      className="btn-detail m-0 btn_x"
                                      onClick={() =>
                                        handleStatusChange(item?._id, "blocked")
                                      }
                                      // disabled={
                                      //   isAccepting || isRejecting || isBlocking
                                      // }
                                    >
                                      {isBlocking === item?._id
                                        ? "Blocking"
                                        : "Block"}
                                      {/* {isBlocking ? "Blocking" : "Block"} */}
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {totalCount > 1 && (
                      <PaginationB
                        page={page}
                        setPage={setPage}
                        totalCount={totalCount}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {isApicall && <BodyLoader />}

      <ConsultantDetailModal
        open={contractorDetail?.isOpen}
        setOpen={() =>
          setContractorDetail((p) => ({ ...p, isOpen: !p.isOpen }))
        }
        data={contractorDetail?.data}
      />
    </>
  );
}
