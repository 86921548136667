import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { useSelector } from "react-redux";
import {
  apiHeader,
  BaseURL,
  formRegEx,
  formRegExReplacer,
  getSpecificSearchParam,
  imageUrl,
  numberWithCommas,
  pdfUrl,
} from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import AdminSideBar from "../components/AdminSideBar";
import { useLocation } from "react-router-dom";
import DetailModal from "../components/DetailModal";
import AreYouSureModal from "../components/AreYouSureModal";
import { currencies } from "../currencies";
import fallbackUser from "../assets/images/fallbackUser.png";
import { FaEnvelope, FaBuilding, FaAddressCard } from "react-icons/fa";
import { MdWork, MdSchool } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa";
import { AiFillCalendar } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { BiRightArrow, BiWorld } from "react-icons/bi";

import { toast } from "react-toastify";
import BodyLoader from "../components/BodyLoader";
import ShowMoreShowLessText from "../components/ShowMoreShowLessText";
import ProposalDetailModal from "../components/ProposalDetailModal";
import BankingDetailModal from "../components/BankingDetailModal";
import { AiOutlinePaperClip } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";

// import { currencies } from "../currencies";
export default function DetailPage() {
  const loc = getSpecificSearchParam("loc");
  const [projectCompleteness, setProjectCompleteness] = useState({
    class: "",
    value: 0,
  });
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );
  const [isApiCall, setIsApicall] = useState(false);
  const [isRequestReleaseModalOpen, setIsRequestReleaseModalOpen] =
    useState(false);

  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const handleClose1 = () => setModalShow(false);
  const projectId = useLocation()?.state;
  const [type, setType] = useState("");

  const [detail, setDetail] = useState([]);
  const [adminforCustomer, setAdminForCustomer] = useState({});
  const [adminforFreelancer, setAdminForFreelancer] = useState({});
  const userId = detail.postedBy?._id;

  const [showProposalModal, setShowProposalModal] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState(null);

  const [isSendingProposal, setIsSendingProposal] = useState(false);

  // Release Request
  const [isMakingReleaseRequest, setIsMakingReleaseRequest] = useState(false);
  const [releaseMilestoneId, setReleaseMilestoneId] = useState("");

  const [payNowRequestData, setPayNowRequestData] = useState(null);
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState(null);

  //

  const [loading, setIsLoading] = useState(false);
  const [termAndConditionData, setTermAndConditionData] = useState(null);
  const [termConditionData, setTermConditionData] = useState(null);

  const PayMileStoneApiUrl = BaseURL(`admin/update-milestone-payment-status`);
  const getProposalApiUrl = BaseURL(
    `users/projectDetails?projectId=${projectId}`
  );

  const [isBankingDetailModalOpen, setIsBankingDetailModalOpen] =
    useState(false);
  const [bankingDetails, setBankingDetails] = useState(null);

  // get data
  async function getData(loader = true) {
    loader && setIsApicall(true);
    const response = await Get(getProposalApiUrl, accessToken);
    loader && setIsApicall(false);
    if (response !== undefined) {
      const _r = response.data.data;
      setDetail(response?.data.data);

      setAdminForCustomer(_r?.accecptedPorposalByCustomer);

      setAdminForFreelancer(_r?.accecptedPorposalByFreelancer);

      // Project Completeness
      let progressClass = "danger";
      let progressValue = (_r.amountPayedToAdmin / _r.amount) * 100;

      if (progressValue <= 10) progressClass = "danger";
      else if (progressValue > 10 && progressValue <= 30)
        progressClass = "warning";
      else if (progressValue > 30 && progressValue <= 80)
        progressClass = "primary";
      else if (progressValue > 80 && progressValue <= 100)
        progressClass = "success";
      else progressClass = "danger";

      setProjectCompleteness({
        class: progressClass,
        value: progressValue,
      });
    }
  }

  const getTermCondition = async () => {
    const url = BaseURL(`cms/page/terms_and_condition`);
    setIsLoading(true);
    const response = await Get(url);
    setIsLoading(false);

    if (response !== undefined) {
      setTermAndConditionData(response?.data?.data);
      setTermConditionData(response?.data?.data?.description2);
    }
  };

  useEffect(() => {
    getData();
    getTermCondition();
  }, []);

  // send Proposal
  async function sendProposal(
    proposalTitle,
    proposalDescription,
    proposalAmount,
    mileStone,
    freelancer,
    resetStates,
    proposalDate,
    termConditionData

    // setFreelancer
  ) {
    // console.log(, "wwwwwwwww");

    const freelanceId =
      type == "freelancer" && freelancer.map((item) => item.id);

    const url =
      type == "customer"
        ? BaseURL(
            `admin/submit-porposal-to-customer?projectId=${projectId}&userId=${userId}`
          )
        : BaseURL(`admin/submit-porposal-to-freelancer?projectId=${projectId}`);

    var totalAmount = mileStone.reduce(function (acc, obj) {
      return acc + obj?.amount * 1;
    }, 0);

    const params = {
      proposalDetails: {
        title: proposalTitle,
        description: proposalDescription,
        amount: proposalAmount,
        status: "active",
        proposalDate,
        methodOfMeasurement: detail?.methodOfMeasurement,
      },
      proposalMilestones: mileStone,
      ...(type == "freelancer" && { userIds: freelanceId }),
      ...(type == "customer" && { terms: termConditionData }),
    };

    const requiredValues = params.proposalDetails;
    for (let key in requiredValues)
      if (requiredValues[key] === "" || requiredValues[key] == null)
        return toast.warn(`${key} is required.`);
    if (totalAmount != proposalAmount)
      return toast.warn(
        `MileStone Amount Must be equal to the total amount of the project (${proposalAmount})`
      );
    setIsSendingProposal(true);
    const response = await Post(url, params, apiHeader(accessToken));
    setIsSendingProposal(false);
    if (response !== undefined) {
      await getData(false);
      resetStates();
      toast.success("Proposal Sent successfully.");
      handleClose1();
    }
  }

  // releaseRequestHandler
  const releaseRequestHandler = async () => {
    const url = BaseURL(
      `admin/make-milestone-release-request?milestoneId=${releaseMilestoneId}&proposalId=${adminforCustomer._id}`
    );

    setIsMakingReleaseRequest(true);
    const response = await Post(url, {}, apiHeader(accessToken));
    setIsMakingReleaseRequest(false);

    if (response !== undefined) {
      await getData();
      toast.success("Release Request Send successfully");
      HandleResetSureModal();
    }
  };

  const HandleResetSureModal = () => {
    setAlertText("");
    setIsRequestReleaseModalOpen(false);
  };

  const HandlePayMileStoneToFreelance = async () => {
    setIsMakingReleaseRequest(true);
    const response = await Post(
      PayMileStoneApiUrl,
      payNowRequestData,
      apiHeader(accessToken)
    );

    if (response !== undefined) {
      setAdminForFreelancer(response?.data?.data);
      toast.success("Milestone Paid successfully");
      HandleResetSureModal();
    }
    setIsMakingReleaseRequest(false);
  };
  const HandlePayNowBtnClick = async (msId, proposalId) => {
    setAlertType("payNow");
    setPayNowRequestData({
      ispaid: true,
      milestoneId: msId,
      proposalId: proposalId,
    });
    setAlertText("Are you sure you want to Pay Now?");
    setIsRequestReleaseModalOpen(true);
  };

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <div className="breedCum">
                    <span>
                      {loc == 1
                        ? "Assigned Projects"
                        : loc == 2
                        ? "Posted Projects"
                        : loc == 3
                        ? "Payments"
                        : loc == 4
                        ? "Payments to Consultants-Outgoing"
                        : loc == 5
                        ? "Notifications"
                        : "My Project"}
                    </span>
                    <BiRightArrow className="ic" />
                    <span>Project Details</span>
                  </div>
                  {/* <div className="Dashboardpage-invoice">
                    <h2>Detail</h2>

                   
                    <div className="innerspan">
                      <span>
                        {" "}
                        {parseFloat(projectCompleteness?.value).toFixed(2)}%
                      </span>
                      <ProgressBar
                        variant={projectCompleteness?.class}
                        now={projectCompleteness?.value}
                        className={projectCompleteness?.class}
                        style={{
                          width: "150px",
                        }}
                      />
                    </div>
                  </div> */}
                </Col>
                <Col lg={12}>
                  <div className="detail_card f-f">
                    <h5 className="t-t-c">{`Title: ${detail?.title}`}</h5>
                    <p className="inner-text bd">Description :</p>
                    <p className="small inner-text">
                      <ShowMoreShowLessText
                        quill={true}
                        text={detail?.description}
                        visibility={500}
                      />
                    </p>
                    <p className="inner-text bd f-f">Method Of Measurement :</p>
                    <p className="small inner-text f-f">
                      {detail?.methodOfMeasurement?.map((item, index) => (
                        <li
                          style={{
                            listStyleType: "none",
                          }}
                        >
                          {item?.value}
                        </li>
                      ))}
                    </p>
                    <p className="f-f">
                      <span className="inner-text bd">Amount: </span>
                      {detail?.currency &&
                        currencies[detail?.currency?.toUpperCase()].symbol}
                      {/* {numeral(detail?.amount).format("0.0a")}{" "} */}
                      {`${numberWithCommas(detail?.amount)}`}
                    </p>
                    <p className="t-t-c f-f">
                      <span className={`inner-text bd`}>Project Status: </span>
                      <span className={`${detail?.projectStatus}`}>
                        {`${detail?.projectStatus?.replace(
                          formRegEx,
                          formRegExReplacer
                        )}`}
                      </span>
                    </p>
                    <p className="t-t-c f-f">
                      <span className={`inner-text bd`}>Timeline: </span>
                      <span>{detail?.timeline}</span>
                    </p>

                    <div className="btn-box">
                      {["pending", "underReview", "inProgress"].includes(
                        detail?.projectStatus
                      ) && (
                        <>
                          {!adminforFreelancer && (
                            <button
                              className={`btn-detail ${
                                !adminforCustomer && "btn-disabled"
                              }`}
                              disabled={!adminforCustomer}
                              onClick={() => {
                                setModalShow(true);
                                setType("freelancer");
                              }}
                            >
                              Proposal For Consultant
                            </button>
                          )}
                          {!adminforCustomer && (
                            <button
                              className="btn-detail"
                              onClick={() => {
                                setModalShow(true);
                                setType("customer");
                              }}
                            >
                              Proposal For Client
                            </button>
                          )}
                        </>
                      )}

                      {/* chat with customer when proposalsForCustomer === 0 */}
                      {detail?.porposalsForCustomer?.length === 0 && (
                        <button
                          className="btn-detail"
                          onClick={() =>
                            history.push({
                              pathname: "/admin/ChatScreen",
                              state: {
                                userId: detail?.postedBy?._id,
                                projectId: projectId,
                                userName: detail?.postedBy?.name,
                                userRole: detail?.postedBy?.role,
                              },
                            })
                          }
                        >
                          Chat with Client
                        </button>
                      )}
                    </div>

                    {/* if proposal rejected by customer */}
                    {adminforCustomer?.status == "rejected" && (
                      <p className="text-danger">
                        Worker has rejected your proposal.
                      </p>
                    )}
                  </div>
                </Col>
              </Row>

              {/* Project Images */}
              {detail?.images?.length > 0 && (
                <Row className="selectImg_box">
                  <Col md={12}>
                    <h5>Images</h5>
                  </Col>
                  {detail?.images.map((image, index) => (
                    <Col lg={4} key={index}>
                      <div
                        className="selectimgs c-p mb-3"
                        onClick={() =>
                          window.open(`${imageUrl}${image}`, "_blank")
                        }
                      >
                        <img src={`${imageUrl}${image}`} alt="project-img" />
                      </div>
                    </Col>
                  ))}
                </Row>
              )}

              {/* Project Docs */}
              {detail?.pdfs?.length > 0 && (
                <Row className="selectImg_box">
                  <Col md={12}>
                    <h5>Drawings and Specifications</h5>
                  </Col>
                  {detail?.pdfs?.map((document, index) => (
                    <Col lg={4} key={index}>
                      <div
                        className="selectimgs select_pdf c-p mb-3"
                        onClick={() =>
                          window.open(`${pdfUrl}${document}`, "_blank")
                        }
                      >
                        <FaFilePdf className="pdf_icon" />
                        <p>Open PDF</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}

              {/* if admin has ever make a proposal to customer */}
              {adminforCustomer && (
                <div className="customer_detail_main">
                  <Col md={12}>
                    <h3>Admin Details (For Client)</h3>
                  </Col>
                  <Col md={12}>
                    <div className="custmorDetail_inner">
                      <Col md={12}>
                        <div className="mb-3 f-f">
                          <h5 className="t-t-c f-f inner-text bd">{`Title: ${adminforCustomer?.title}`}</h5>
                        </div>
                        {/* <span class="inner-text bd">Description: </span> */}
                      </Col>
                      <Col md={12}>
                        <span class="inner-text bd">Description: </span>
                        <div className="custmorDetail_innerText f-f">
                          <span className="mb-2 small f-f">
                            {/* {adminforCustomer?.description} */}
                            <ShowMoreShowLessText
                              quill={true}
                              text={adminforCustomer?.description}
                              visibility={500}
                            />
                          </span>
                          <div className="mb-2  f-f">
                            <span class="inner-text bd">Amount: </span>
                            {detail?.currency &&
                              currencies[detail?.currency?.toUpperCase()]
                                .symbol}
                            {numberWithCommas(adminforCustomer?.amount)}
                            {/* {numeral(adminforCustomer?.amount).format("0,00a")} */}
                          </div>
                          <div className="t-t-c mb-2">
                            <span class={`inner-text bd `}>
                              Project End Date:{" "}
                            </span>

                            {adminforCustomer?.proposalDate}
                          </div>
                          <div className="t-t-c mb-2">
                            <span class={`inner-text bd`}>Status: </span>
                            <span className={`${adminforCustomer?.status}`}>
                              {adminforCustomer?.status?.replace(
                                formRegEx,
                                formRegExReplacer
                              )}
                            </span>
                          </div>
                        </div>

                        <div className="btn-box">
                          <button
                            className="btn-detail"
                            onClick={() =>
                              history.push({
                                pathname: "/admin/ChatScreen",
                                state: {
                                  userId: adminforCustomer?.sendTo?._id,
                                  projectId: adminforCustomer?.projectId,
                                  proposalId: adminforCustomer?._id,
                                  userName: adminforCustomer?.sendTo?.name,
                                  userRole: adminforCustomer?.sendTo?.role,
                                },
                              })
                            }
                          >
                            Chat with Client
                          </button>
                        </div>
                      </Col>
                      {adminforCustomer?.milestones?.length > 0 && (
                        <div className="detailPage_table">
                          <Col className="mb-2">
                            <span className="inner-text bd">Milestones: </span>
                          </Col>
                          <Col lg={12}>
                            <div className="invoice-Table">
                              <table>
                                <thead className="invoice-head">
                                  <tr>
                                    <th>No.</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Budget</th>
                                    <th>Status</th>
                                    <th>Payment Received from Client</th>
                                    <th>Invoice Sent to Client</th>
                                    <th>Invoice</th>
                                    <th>Invoice Password</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {adminforCustomer?.milestones?.map(
                                    (item, index) => {
                                      return (
                                        <tr
                                          className="invoice-row"
                                          key={item?._id}
                                        >
                                          <td>{++index}</td>
                                          <td>{item?.title}</td>
                                          <td>
                                            <ShowMoreShowLessText
                                              text={item?.description}
                                              visibility={8}
                                            />
                                          </td>
                                          <td>
                                            {detail?.currency &&
                                              currencies[
                                                detail?.currency?.toUpperCase()
                                              ].symbol}
                                            {numberWithCommas(item?.amount)}
                                            {/* {numeral(item?.amount).format(
                                              "0.0a"
                                            )}{" "} */}
                                          </td>
                                          <td
                                            className={`t-t-c ${item?.status}`}
                                          >
                                            {item?.status}
                                          </td>
                                          <td>
                                            {item?.isMilestonePaid
                                              ? "Yes"
                                              : "No."}
                                          </td>
                                          <td>
                                            {item?.isMilestonePaid &&
                                            item?.makeReleaseRequest ? (
                                              "Completed"
                                            ) : item?.makeReleaseRequest ? (
                                              "Already Requested"
                                            ) : (
                                              <button
                                                className="btn-detail m-0"
                                                onClick={() => {
                                                  setReleaseMilestoneId(
                                                    item?._id
                                                  );
                                                  setAlertText(
                                                    "Are you sure you want to make a release request?"
                                                  );
                                                  setIsRequestReleaseModalOpen(
                                                    true
                                                  );
                                                  setAlertType(
                                                    "requestRelease"
                                                  );
                                                }}
                                              >
                                                Request Release
                                              </button>
                                            )}
                                          </td>
                                          <td>
                                            {item?.invoice ? (
                                              <div
                                                className="c-p"
                                                onClick={() =>
                                                  window.open(
                                                    `${pdfUrl}${item?.invoice}`
                                                  )
                                                }
                                              >
                                                <span>Open PDF</span>
                                                <FaFilePdf className="close-icon" />
                                                <span></span>
                                              </div>
                                            ) : (
                                              "-"
                                            )}
                                          </td>
                                          <td>
                                            {item?.pdfPassword ? (
                                              <CopyToClipboard
                                                text={item?.pdfPassword}
                                                onCopy={() =>
                                                  toast.success(
                                                    "Password copied to clipboard."
                                                  )
                                                }
                                              >
                                                <div className="c-p">
                                                  <AiOutlinePaperClip
                                                    style={{ fontSize: "20px" }}
                                                  />
                                                  <span>(Click to Copy)</span>
                                                </div>
                                              </CopyToClipboard>
                                            ) : (
                                              "-"
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              )}

              {/* if admin has ever make a proposal to freelancer */}
              {adminforFreelancer && (
                <div className="customer_detail_main">
                  <Col md={12}>
                    <h3>Admin Details (For Consultant)</h3>
                  </Col>
                  <Col md={12}>
                    <div className="custmorDetail_inner">
                      <Col md={12}>
                        {/* <div className="mb-2">
                          <span className="inner-text bd">Title: </span>
                          {adminforFreelancer?.title}
                        </div> */}
                        <div className="mb-3 f-f">
                          <h5 className="t-t-c f-f inner-text bd">{`Title: ${adminforFreelancer?.title}`}</h5>
                        </div>
                      </Col>
                      <Col md={12}>
                        <span class="inner-text bd">Description: </span>
                        <div className="custmorDetail_innerText">
                          <span className="mb-2 small">
                            {/* {adminforFreelancer?.description} */}
                            <ShowMoreShowLessText
                              quill={true}
                              text={adminforFreelancer?.description}
                              visibility={500}
                            />
                          </span>
                          <div className="mb-2">
                            <span class="inner-text bd">Amount: </span>
                            {detail?.currency &&
                              currencies[detail?.currency?.toUpperCase()]
                                .symbol}
                            {/* {numeral(adminforFreelancer?.amount).format(
                              "0,00a"
                            )}{" "} */}
                            {numberWithCommas(adminforFreelancer?.amount)}
                          </div>
                          <div className="t-t-c mb-2">
                            <span class={`inner-text bd `}>
                              Project End Date:{" "}
                            </span>

                            {adminforFreelancer?.proposalDate}
                          </div>
                          <div className="t-t-c mb-2">
                            <span
                              class={`inner-text bd ${adminforFreelancer?.status}`}
                            >
                              Status:{" "}
                            </span>
                            {adminforFreelancer?.status?.replace(
                              formRegEx,
                              formRegExReplacer
                            )}
                          </div>
                        </div>

                        <div className="btn-box">
                          {adminforFreelancer && (
                            <button
                              className="btn-detail me-3"
                              onClick={() => {
                                setBankingDetails(
                                  adminforFreelancer?.sendTo?.bankDetails
                                );
                                setIsBankingDetailModalOpen(true);
                              }}
                            >
                              Show Banking Details
                            </button>
                          )}
                          <button
                            className="btn-detail"
                            onClick={() =>
                              history.push({
                                pathname: "/admin/ChatScreen",
                                state: {
                                  userId: adminforFreelancer?.sendTo?._id,
                                  projectId: adminforFreelancer?.projectId,
                                  proposalId: adminforFreelancer?._id,
                                  userName: adminforFreelancer?.sendTo?.name,
                                  userRole: adminforFreelancer?.sendTo?.role,
                                },
                              })
                            }
                          >
                            Chat with Consultant
                          </button>
                        </div>
                      </Col>
                      {adminforFreelancer?.milestones?.length > 0 && (
                        <div className="detailPage_table">
                          <Col className="mb-2">
                            <span className="inner-text bd">Milestones: </span>
                          </Col>
                          <Col lg={12}>
                            <div className="invoice-Table">
                              <table>
                                <thead className="invoice-head">
                                  <tr>
                                    <th>No.</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Budget</th>
                                    <th>Status</th>
                                    <th>Paid (By Admin)</th>
                                    <th>
                                      Withdrawal Request (By 
                                      Consultant)
                                    </th>
                                    <th>Invoice</th>
                                    <th>Invoice Password</th>
                                    {/* <th>Withdrawal Detail</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {adminforFreelancer?.milestones?.map(
                                    (item, index) => {
                                      return (
                                        <tr
                                          className="invoice-row"
                                          key={item?._id}
                                        >
                                          <td>{++index}</td>
                                          <td>{item?.title}</td>
                                          <td>
                                            <ShowMoreShowLessText
                                              text={item?.description}
                                              visibility={8}
                                            />
                                          </td>
                                          <td>
                                            {detail?.currency &&
                                              currencies[
                                                detail?.currency?.toUpperCase()
                                              ].symbol}
                                            {/* {numeral(item?.amount).format(
                                              "0.0a"
                                            )} */}
                                            {numberWithCommas(item?.amount)}
                                          </td>
                                          <td
                                            className={`t-t-c ${item?.status}`}
                                          >
                                            {item?.status}
                                          </td>
                                          <td>
                                            {item?.isMilestonePaid
                                              ? "Yes"
                                              : "No."}
                                          </td>
                                          <td>
                                            {item?.status == "completed" &&
                                            item?.isMilestonePaid == true ? (
                                              "Paid"
                                            ) : item?.status == "completed" &&
                                              item?.makeWidthDrawlRequest ==
                                                true ? (
                                              <div className="table-detail-btn">
                                                <button
                                                  onClick={() => {
                                                    HandlePayNowBtnClick(
                                                      item?._id,
                                                      adminforFreelancer?._id
                                                    );
                                                  }}
                                                >
                                                  Pay Now
                                                </button>
                                              </div>
                                            ) : (
                                              "None"
                                            )}
                                          </td>
                                          <td>
                                            {item?.invoice ? (
                                              <div
                                                className="c-p"
                                                onClick={() =>
                                                  window.open(
                                                    `${pdfUrl}${item?.invoice}`,
                                                    "_blank"
                                                  )
                                                }
                                              >
                                                <span>Open PDF</span>
                                                <FaFilePdf className="close-icon" />
                                                <span></span>
                                              </div>
                                            ) : (
                                              "-"
                                            )}
                                          </td>
                                          <td>
                                            {item?.pdfPassword ? (
                                              <CopyToClipboard
                                                text={item?.pdfPassword}
                                                onCopy={() =>
                                                  toast.success(
                                                    "Password copied to clipboard."
                                                  )
                                                }
                                              >
                                                <div className="c-p">
                                                  <AiOutlinePaperClip
                                                    style={{ fontSize: "20px" }}
                                                  />
                                                  <span>(Click to Copy)</span>
                                                </div>
                                              </CopyToClipboard>
                                            ) : (
                                              "-"
                                            )}
                                          </td>
                                          {/* <td>
                                            {item?.widthDrawlDetail ? (
                                              <button
                                                className="btn-detail banking-btn m-0"
                                                onClick={() => {
                                                  setBankingDetails(
                                                    item?.widthDrawlDetail
                                                  );
                                                  setIsBankingDetailModalOpen(
                                                    true
                                                  );
                                                }}
                                              >
                                                View Banking Details
                                              </button>
                                            ) : (
                                              "-"
                                            )}
                                          </td> */}
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              )}

              {/* Customer short DetailF */}
              <div className="customer_detail_main">
                <Col md={12}>
                  <h3>(For Client) </h3>
                </Col>
                <Col md={12}>
                  <div className="custmorDetail_inner">
                    <Row>
                      <Col md={2} className="profileInnerImg">
                        <div className="innerImg">
                          <img
                            src={`${imageUrl}${detail?.postedBy?.photo}`}
                            alt="Client"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = fallbackUser;
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={10}>
                        <div className="custmorDetail_innerText">
                          <p className="big t-t-c">{detail?.postedBy?.name}</p>
                          <ul className="user-info-card-list">
                            <li>
                              <FaEnvelope className="customerDetail_icon" />{" "}
                              {detail?.postedBy?.email}
                            </li>
                            <li>
                              <FaBuilding className="customerDetail_icon" />{" "}
                              {detail?.postedBy?.company}
                            </li>
                            <li>
                              <MdWork className="customerDetail_icon" />{" "}
                              {detail?.postedBy?.job}
                            </li>
                            <li>
                              <FaAddressCard className="customerDetail_icon" />{" "}
                              {detail?.postedBy?.address}
                            </li>
                            <li>
                              <BiWorld className="customerDetail_icon" />{" "}
                              <a
                                href={detail?.postedBy?.companyWebsite}
                                target="_blank"
                              >
                                {detail?.postedBy?.companyWebsite}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </div>

              {/* Assigned Freelancer short DetailF */}
              {detail?.assignTo && (
                <div className="customer_detail_main">
                  <Col md={12}>
                    <h3>Assigned Consultant</h3>
                  </Col>
                  <Col md={12}>
                    <div className="custmorDetail_inner">
                      <Row>
                        <Col md={2} className="profileInnerImg">
                          <div className="innerImg">
                            <img
                              src={`${imageUrl}${detail?.assignTo?.photo}`}
                              alt="freelancer"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = fallbackUser;
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={10}>
                          <div className="custmorDetail_innerText">
                            <p className="big t-t-c">
                              {detail?.assignTo?.name}
                            </p>
                            <ul className="user-info-card-list">
                              <li>
                                <FaEnvelope className="customerDetail_icon" />{" "}
                                {detail?.assignTo?.email}
                              </li>
                              <li>
                                <AiFillCalendar className="customerDetail_icon" />{" "}
                                {detail?.assignTo?.age} years old.
                              </li>
                              <li>
                                <MdSchool className="customerDetail_icon" />{" "}
                                {detail?.assignTo?.qualification}
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </div>
              )}
              {/* All proposals For customer created by admin */}
              {detail?.porposalsForCustomer?.length > 0 && (
                <div className="detailPage_table">
                  <Col md={12}>
                    <h3>{`Proposal
                    ${detail?.porposalsForCustomer?.length > 1 ? "" : ""}
                     For Client`}</h3>
                  </Col>
                  <Col lg={12}>
                    <div className="invoice-Table">
                      <table>
                        <thead className="invoice-head">
                          <tr>
                            <th>No.</th>
                            <th>Project Title</th>
                            <th>Client Name</th>
                            <th>Budget</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {detail?.porposalsForCustomer.map((item, index) => (
                          <tbody>
                            {console.log("item?.status -------------------------------", item)}
                            <tr className="invoice-row">
                              <td>{index + 1}</td>
                              <td>{item?.title}</td>
                              <td>{item?.sendTo?.name}</td>
                              <td>
                                {/* ${item?.amount} */}
                                {detail?.currency &&
                                  currencies[detail?.currency?.toUpperCase()]
                                    .symbol}
                                {/* {numeral(item?.amount).format("0.0a")}{" "} */}
                                {numberWithCommas(item?.amount)}
                              </td>
                              <td
                                className={`t-t-c ${item?.status} 
                                ${item?.status == "rejected" && "rejected"}
                                ${item?.status == "pending" && "pending"}`}
                              >
                                {item?.status}
                              </td>
                              <td>
                                <div className="table-detail-btn">
                                  <button
                                    className="me-3"
                                    onClick={() => {
                                      setSelectedProposal(item);
                                      setShowProposalModal(!showProposalModal);
                                    }}
                                  >
                                    View Details
                                  </button>
                                  {!adminforCustomer && (
                                    <button
                                      onClick={() =>
                                        history.push({
                                          pathname: "/admin/ChatScreen",
                                          state: {
                                            userId: item?.sendTo?._id,
                                            projectId: detail?._id,
                                            proposalId: item?._id,
                                            userName: item?.sendTo?.name,
                                            userRole: item?.sendTo?.role,
                                          },
                                        })
                                      }
                                    >
                                      Chat Now
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </Col>
                </div>
              )}

              {/* All proposals For Freelance created by admin */}
              {detail?.porposalsForFreelancer?.length > 0 && (
                <div className="detailPage_table">
                  <Col md={12}>
                    <h3>{`Proposal${
                      detail?.porposalsForFreelancer?.length > 1 ? "" : ""
                    } For Consultant`}</h3>
                  </Col>
                  <Col lg={12}>
                    <div className="invoice-Table">
                      <table>
                        <thead className="invoice-head">
                          <tr>
                            <th>No.</th>
                            <th>Project Title</th>
                            <th>Consultant Name</th>
                            <th>Budget</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {detail?.porposalsForFreelancer.map((item, index) => (
                          <tbody>
                            <tr className="invoice-row">
                              <td>{index + 1}</td>
                              <td>{item?.title}</td>
                              <td className="t-t-c">{item?.sendTo?.name}</td>
                              <td>
                                {/* ${item?.amount} */}
                                {detail?.currency &&
                                  currencies[detail?.currency?.toUpperCase()]
                                    .symbol}
                                {/* {numeral(item?.amount).format("0.00a")}{" "} */}
                                {numberWithCommas(item?.amount)}
                              </td>
                              <td
                                className={`t-t-c ${item?.status} 
                                ${item?.status == "rejected" && "rejected"}
                                ${item?.status == "pending" && "pending"}`}
                              >
                                {item?.status}
                              </td>
                              <td>
                                <div className="table-detail-btn">
                                  <button
                                    className="me-2"
                                    onClick={() => {
                                      setSelectedProposal(item);

                                      setShowProposalModal(!showProposalModal);
                                    }}
                                  >
                                    View Details
                                  </button>
                                  {!adminforFreelancer && (
                                    <button
                                      onClick={() =>
                                        history.push({
                                          pathname: "/admin/ChatScreen",
                                          state: {
                                            userId: item?.sendTo?._id,
                                            projectId: detail?._id,
                                            proposalId: item?._id,
                                            userName: item?.sendTo?.name,
                                            userRole: item?.sendTo?.role,
                                          },
                                        })
                                      }
                                    >
                                      Chat Now
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </Col>
                </div>
              )}
            </Col>
          </Row>
          {modalShow && (
            <DetailModal
              modalShow={modalShow}
              handleClose1={handleClose1}
              type={type}
              isApiCall={isSendingProposal}
              sendProposal={sendProposal}
              detail={detail}
              termConditionData={termConditionData}
              setTermConditionData={setTermConditionData}
            />
          )}
          <ProposalDetailModal
            open={showProposalModal}
            setOpen={setShowProposalModal}
            data={selectedProposal}
            detail={detail}
            test={true}
          />
        </Container>
        <AreYouSureModal
          setOpen={setIsRequestReleaseModalOpen}
          open={isRequestReleaseModalOpen}
          handleYes={
            alertType == "payNow"
              ? HandlePayMileStoneToFreelance
              : releaseRequestHandler
          }
          title={alertText}
          isLoading={isMakingReleaseRequest}
          modalbox2={true}
        />
        {/* Banking Detail Mopdal */}
        {isBankingDetailModalOpen && (
          <BankingDetailModal
            setOpen={setIsBankingDetailModalOpen}
            open={isBankingDetailModalOpen}
            handleYes={() => setIsBankingDetailModalOpen(false)}
            title={bankingDetails}
          />
        )}
      </section>

      {isApiCall && <BodyLoader />}
    </>
  );
}
