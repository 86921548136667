import React from "react";
import { Spinner } from "react-bootstrap";

const BodyLoader = ({ dark }) => {
  return (
    <div
      className="body-loader"
      style={
        dark && {
          backgroundColor: "rgba(28,183,53, 0.95)",
        }
      }
    >
      <div class="spinner-border text-light" role="status">
      </div>
      

    </div>
  );
};

export default BodyLoader;
