import React from "react";
import { Col } from "react-bootstrap";
import ModalSkeleton from "./ModalSkeleton";
import ReactHtmlParser from "react-html-parser";
import ShowMoreShowLessText from "./ShowMoreShowLessText";
import { currencies } from "../currencies";
import { numberWithCommas } from "../Config/apiUrl";
var numeral = require("numeral");

function ProposalDetailModal({ open, setOpen, data, detail, test }) {
  console.log(data);
  return (
    <ModalSkeleton open={open} setOpen={setOpen} test maxContent>
      <>
        <div className="proposalModal_outerDiv">
          <Col md={12}>
            <div className="mb-2">
              <span className="inner-text bd">Project Title: </span>
              {data?.title}
            </div>
          </Col>
          <Col md={12}>
            <span class="inner-text bd">Description: </span>
            <div className="custmorDetail_innerText">
              <span className="mb-2 small parser-para">
                {ReactHtmlParser(data?.description)}
              </span>
              {data?.methodOfMeasurement?.length > 0 && (
                <div className="mb-2">
                  <span class="inner-text bd">Method Of Measurement: </span>
                  {data?.methodOfMeasurement?.map((item, index) => (
                    <li
                      key={index}
                      style={{
                        listStyleType: "none",
                      }}
                    >
                      {item?.value}
                    </li>
                  ))}
                </div>
              )}
              <div className="mb-2">
                <span class="inner-text bd">Amount: </span>
                {detail?.currency &&
                  currencies[detail?.currency.toUpperCase()].symbol}
                {numberWithCommas(data?.amount)}

                {/* {numeral(data?.amount).format("0,00a")} */}
              </div>
              <div className="mb-2">
                <span class="inner-text bd">Project End Date: </span>
                {data?.proposalDate}
              </div>
              <div className="mb-2">
                <span class="inner-text bd">Status: </span>
                {data?.status}
              </div>
              {data?.rejectionReason && (
                <div className="mb-2">
                  <span class="inner-text bd">Rejection Reason: </span>
                  <ShowMoreShowLessText text={data?.rejectionReason} />
                </div>
              )}
            </div>
          </Col>
          {data?.milestones?.length > 0 && (
            <div className="detailPage_table">
              <Col className="mb-2">
                <span className="inner-text bd">Milestones: </span>
              </Col>
              <Col lg={12}>
                <div className="invoice-Table">
                  <table>
                    <thead className="invoice-head">
                      <tr>
                        <th>No.</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Budget</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.milestones?.map((item, index) => {
                        return (
                          <tr className="invoice-row" key={item?._id}>
                            <td>{++index}</td>
                            <td>{item?.title}</td>
                            <td>
                              <ShowMoreShowLessText
                                text={item?.description}
                                visibility={8}
                              />
                            </td>
                            <td>
                              {/* ${numeral(item?.amount).format("0,00a")} */}
                              {detail?.currency &&
                                currencies[detail?.currency.toUpperCase()]
                                  .symbol}
                              {/* {numeral(item?.amount).format("0.0a")} */}
                              {numberWithCommas(item?.amount)}
                            </td>
                            <td
                              className={`${
                                item?.status == "active" && "accepted"
                              } 
                                ${item?.status == "rejected" && "rejected"}
                                ${item?.status == "pending" && "pending"}`}
                            >
                              {item?.status}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </div>
          )}
        </div>
      </>
    </ModalSkeleton>
  );
}

export default ProposalDetailModal;
