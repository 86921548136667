import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { useSelector } from "react-redux";
import { BaseURL, recordsLimit } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import AdminSideBar from "../components/AdminSideBar";
import PaginationB from "../components/PaginationB";
import BodyLoader from "../components/BodyLoader";
import ConsultantDetailModal from "../components/ConsultantDetailModal";

export default function Workers() {
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  const [Workers, setWorkers] = useState([]);
  const [isApicall, setIsApicall] = useState(false);

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [contractorDetail, setContractorDetail] = useState({
    isOpen: false,
    data: null,
  });

  async function getData(_page) {
    const url = BaseURL(`admin/workers?limit=${recordsLimit}&page=${_page}`);
    setIsApicall(true);
    const response = await Get(url, accessToken);
    setIsApicall(false);
    if (response !== undefined) {
      setWorkers(response?.data.data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
  }

  useEffect(() => {
    getData(page);
  }, [page]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section className="Dashboardpage-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <div className="Dashboardpage-invoice mt-0">
                    <h2>Registered Clients</h2>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="invoice-Table">
                    <table>
                      <thead className="invoice-head">
                        <tr>
                          <th>No.</th>
                          <th>Name</th>
                          <th>Email</th>
                          {/* <th>Contact No.</th>
                          <th>Last Login</th>
                          <th>Status</th>
                          <th>Created at</th> */}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Workers.map((item, index) => {
                          return (
                            <tr className="invoice-row projectsection ">
                              <td>{index + 1}</td>
                              <td className="t-t-c">{item.name}</td>
                              <td>{item.email}</td>
                              {/* <td>{item.contactNo}</td>
                              <td>
                                {moment(item.lastLogin).format("YYYY-MM-DD")}
                              </td>
                              <td>{item.deactivate ? "Deactive" : "Active"}</td>
                              <td>{moment(item.createdAt).format("lll")}</td> */}
                              <td>
                                <button
                                  className="btn-detail m-0 me-2 btn_x w-113"
                                  onClick={() =>
                                    setContractorDetail({
                                      isOpen: true,
                                      data: item,
                                    })
                                  }
                                  style={{
                                    padding: "11px",
                                  }}
                                >
                                  View Details
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {totalCount > 1 && (
                      <PaginationB
                        page={page}
                        setPage={setPage}
                        totalCount={totalCount}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {isApicall && <BodyLoader />}

      <ConsultantDetailModal
        open={contractorDetail?.isOpen}
        setOpen={() =>
          setContractorDetail((p) => ({ ...p, isOpen: !p.isOpen }))
        }
        data={contractorDetail?.data}
      />
    </>
  );
}
