import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import logo from "../assets/images/logo color.png";
import "../assets/styles/header.css";
import { BsPlus } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BaseURL, apiHeader } from "../Config/apiUrl";
import { Post } from "../Axios/AxiosFunctions";
import { signOutRequest } from "../store/Actions/authAction";
import BodyLoader from "./BodyLoader";
import { useState } from "react";

function Header() {
  const dispatch = useDispatch();
  const {
    isLogin,
    access_token: accessToken,
    user,
  } = useSelector((state) => state.authReducer);

  const [isLogoutApiCall, setIsLogoutApiCall] = useState(false);

  const history = useHistory();
  function redirectOnLink(link) {
    history.push(link);
  }

  async function logoutUser() {
    const url = BaseURL("users/logout");
    setIsLogoutApiCall(true);
    const response = await Post(url, {}, apiHeader(accessToken));
    setIsLogoutApiCall(false);
    if (response !== undefined) {
      dispatch(signOutRequest());
      history.push("/");
    }
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand>
          <h3>Demo</h3>
          {/* <img
            src={logo}
            alt="logo"
            style={{
              width: "138px",
            }}
          /> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto navbar-links ">
            {isLogin ? (
              <Nav.Link onClick={() => logoutUser()}>Logout</Nav.Link>
            ) : (
              <>
                <Nav.Link onClick={() => redirectOnLink("/login")}>
                  Log In{" "}
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>

        {isLogoutApiCall && <BodyLoader />}
      </Container>
    </Navbar>
  );
}

export default Header;
