import "./App.css";
import "./assets/styles/addProject.css";
import "./assets/styles/sidebar.css";
import "./assets/styles/areYouSureModal.css";
import "./assets/styles/withdrawlRequest.css";
import "./assets/styles/chatScreen.css";
import "./assets/styles/responsive.css";
import "./assets/styles/Footer.css";
import "./assets/styles/Dashboardpage.css";
// import "./assets/styles/Contactus.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/header.jsx";
import SignInPage from "./pages/SignInPage";
import Admindashboard from "./pages/Admindashboard.jsx";
import AssignProjects from "./pages/AssignProjects.jsx";
import PostedProjects from "./pages/PostedProjects.jsx";
import Workers from "./pages/Workers.jsx";
import Payments from "./pages/Payments.jsx";
import Contractors from "./pages/Contractors.jsx";
import WithdrawalRequest from "./pages/WithdrawalRequest";
import DetailPage from "./pages/DetailPage";
import { ToastContainer,Zoom } from "react-toastify";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.min.css";
import ChatScreen from "./pages/chatScreen";
import Notificationscreen from "./pages/Notificationscreen";
import Withdraw from "./pages/Withdraw";
import Home_cms from "./pages/Home-cms";
import Services_cms from "./pages/Servicee-cms";
import Order_cms from "./pages/Order-cms";
import About_cms from "./pages/About-cms";
import ContactUs_cms from "./pages/ContactUs_cms";
import Cruds from "./pages/Cruds";
import Cms_Pages from "./pages/cms-pages";
import contact_us from "./pages/ContactUs";
import Footer_cms from "./pages/Footer-cms";
import Support_cms from "./pages/support-cms";
import Blogs from "./pages/Blogs";
import Announcements from "./pages/Announcements";
import "react-quill/dist/quill.snow.css";
import ChatNotifications from "./pages/ChatNotifications";

function App() {
  return (
    <>
      <Router>
      <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Zoom}
       />
        <Header />
        <Switch>
          <Route exact path="/" component={SignInPage} />
          <Route exact path="/admindashboard" component={Admindashboard} />
          <Route exact path="/admin/assignProject" component={AssignProjects} />
          <Route exact path="/admin/payments" component={Payments} />
          <Route exact path="/admin/workers" component={Workers} />
          <Route exact path="/admin/contractors" component={Contractors} />
          <Route exact path="/admin/blogs" component={Blogs} />
          <Route exact path="/admin/announcements" component={Announcements} />
          <Route exact path="/admin/ChatScreen" component={ChatScreen} />
          <Route
            exact
            path="/admin/withdrawalRequest"
            component={WithdrawalRequest}
          />
          <Route exact path="/admin/withdraw" component={Withdraw} />
          <Route
            exact
            path="/admin/notifications"
            component={Notificationscreen}
          />
          <Route
            exact
            path="/admin/chat-notifications"
            component={ChatNotifications}
          />
          <Route
            exact
            path="/admin/postedProjects"
            component={PostedProjects}
          />
          <Route exact path="/admin/detailPage" component={DetailPage} />
          <Route exact path="/admin/home-cms" component={Home_cms} />
          <Route exact path="/admin/services-cms" component={Services_cms} />
          <Route exact path="/admin/order-cms" component={Order_cms} />
          <Route exact path="/admin/about-cms" component={About_cms} />
          <Route exact path="/admin/contactus-cms" component={ContactUs_cms} />
          <Route exact path="/admin/footer-cms" component={Footer_cms} />
          <Route exact path="/admin/cms-crud" component={Cruds} />
          <Route exact path="/admin/cms-pages" component={Cms_Pages} />
          <Route exact path="/admin/contact-us" component={contact_us} />
          <Route exact path="/admin/support-cms" component={Support_cms} />
          {/* Remaining Work */}
          {/* <Route exact path="/my-proposals" component={MyProposals} /> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
