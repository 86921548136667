import React, { useState, useEffect } from "react";
import AdminSideBar from "../components/AdminSideBar";
import { Container, Row, Col, Form } from "react-bootstrap";
import AddImageComponent from "../components/AddImage";
import { Get,Post } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL, imageUrl, urlToObject } from "../Config/apiUrl";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BodyLoader from "../components/BodyLoader";

function ContactUs_cms() {
  const location = useLocation();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const {
    state: { pageName, pageId },
  } = location;
  const accessToken = useSelector((state) => state.authReducer.access_token);

  //  section1 states

  const [sec1Heading, setSec1Heading] = useState("");
  const [sec1image, setSec1image] = useState();

  //  section2 states

  const [sec2Heading, setSec2Heading] = useState("");
  const [sec2Description, setSec2Description] = useState("");

  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName,

      sec1Heading,
      sec1CoverImage: sec1image,

      sec2Heading,
      sec2Description,

      email,
      phoneNo,
      mobileNo,
      facebook,
      instagram,
      linkedIn,
      whatsapp,
    };

    const formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }

    //  post Api contact us Page

    const url = BaseURL("cms/page/update");
    setIsloading(true);
    const response = await Post(url, formData, apiHeader(accessToken));
    setIsloading(false);

    if (response !== undefined) {
      toast.success("Services Page data updated successfully");
    }
  };

  // Get api contact us page
  const getData = async () => {
    const url = BaseURL(`cms/page/${pageName}`);
    setIsApiCall(true);
    const response = await Get(url, apiHeader(accessToken));
    setIsApiCall(false);
    if (response !== undefined) {
      let data = response?.data?.data;


      setSec1Heading(data?.sec1Heading);

      setSec2Heading(data?.sec2Heading);
      setSec2Description(data?.sec2Description);

      setEmail(data?.email);
      setMobileNo(data?.mobileNo);
      setPhoneNo(data?.phoneNo);
      setFacebook(data?.facebook);
      setWhatsapp(data?.whatsapp);
      setLinkedIn(data?.linkedIn);
      setInstagram(data?.instagram);

      //  cover  Image
      const _section1Image1 = await urlToObject(
        `${imageUrl}${data?.sec1CoverImage}`
      );
      setSec1image(_section1Image1);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="add-project-section">
        <Container>
          <Row>
            <Col lg={3} md={12}>
              <AdminSideBar />
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <h3>Contactus Page :</h3>
                </Col>
                <Col lg={12}>
                  <Row>
                    {/* Hero Section */}

                    <Col lg={12}>
                      <h4>Section1:</h4>
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec1Heading}
                        onChange={(e) => setSec1Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={6} className="mt-3">
                      <h5>Image:</h5>
                      <AddImageComponent
                        onSubmit={(data) => setSec1image(data)}
                        initialState={sec1image}
                        index={null}
                        item={sec1image}
                      />
                    </Col>

                    {/* Section2 */}

                    <Col lg={12} className="mt-5">
                      <h4>Section2:</h4>
                    </Col>

                    <Col lg={12} className="mt-3">
                      <h5>Heading:</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Heading Text"
                        value={sec2Heading}
                        onChange={(e) => setSec2Heading(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Description:</h5>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter  Description"
                        value={sec2Description}
                        onChange={(e) => setSec2Description(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Email:</h5>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Mobile No..</h5>
                      <Form.Control
                        type="number"
                        placeholder="Enter Number"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Phone No..</h5>
                      <Form.Control
                        type="number"
                        placeholder="Enter Number"
                        value={phoneNo}
                        onChange={(e) => setPhoneNo(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Facebook</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Facebook link"
                        value={facebook}
                        onChange={(e) => setFacebook(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>Instagram</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Instagram link"
                        value={instagram}
                        onChange={(e) => setInstagram(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>LinkedIn</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter LinkedIn link"
                        value={linkedIn}
                        onChange={(e) => setLinkedIn(e.target.value)}
                      />
                    </Col>
                    <Col lg={12} className="mt-3">
                      <h5>WhatsApp</h5>
                      <Form.Control
                        type="number"
                        placeholder="Enter Whatsapp No"
                        value={whatsapp}
                        onChange={(e) => setWhatsapp(e.target.value)}
                      />
                    </Col>
                    <Col lg={12}>
                      <div className="save-btn">
                        <button
                          className="save-btn"
                          disabled={isApiCall}
                          onClick={() => handleSubmit()}
                        >
                          {isloading ? "please wait..." : "Save"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {isApiCall && <BodyLoader />}
    </>
  );
}

export default ContactUs_cms;
