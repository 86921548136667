import React from "react";
import { Modal } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";

import ReactQuill from "react-quill";

export default function TermsAndConditionsModal({
  mileStoneModal,
  handleClose,
  termConditionData,
  setTermConditionData,
  sendProposal,
  proposalTitle,
  proposalDescription,
  proposalAmount,
  mileStone,
  freelancer,
  resetStates,
  isApiCall,
  timeline,
}) {
  return (
    <Modal
      show={mileStoneModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {/* Proposal For {type == "freelancer" ? "Freelancer" : "Customer"} */}
          Terms And Conditions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <ReactQuill
            className="modal-inp mt-2"
            value={termConditionData}
            onChange={(e) => setTermConditionData(e)}
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="modal-btn"
          disabled={isApiCall}
          onClick={() => {
            sendProposal(
              proposalTitle,
              proposalDescription,
              proposalAmount,
              mileStone,
              freelancer,
              resetStates,
              timeline,
              termConditionData,
              // handleClose1(),
              handleClose()
            );
          }}
        >
          {isApiCall ? "Please wait..." : "Submit"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
