import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../assets/styles/Admindashboard.css";
import { toast } from "react-toastify";

export default function MileStoneModal({
  mileStoneModal,
  handleClose,
  setMileStone,
  type,
  editedMilestoneItem,
  mileStone: _mileStone,
  proposalAmount,
}) {
  const [mileStoneTitle, setMileStoneTitle] = useState("");
  const [mileStoneDescription, setMileStoneDescription] = useState("");
  const [mileStoneAmount, setMileStoneAmount] = useState(0);

  const [fundsLeft, setFundsLeft] = useState(
    parseFloat(proposalAmount) -
      _mileStone?.reduce((val, acc) => (val += parseFloat(acc?.amount)), 0)
  );

  useEffect(() => {
    if (editedMilestoneItem?.item !== null) {
      const newArray = [..._mileStone];
      newArray.splice(editedMilestoneItem?.index, 1);
      console.log(newArray);

      setFundsLeft(
        parseFloat(proposalAmount) -
          newArray?.reduce((val, acc) => (val += parseFloat(acc?.amount)), 0) -
          parseFloat(mileStoneAmount || 0)
      );
    } else {
      setFundsLeft(
        parseFloat(proposalAmount) -
          _mileStone?.reduce(
            (val, acc) => (val += parseFloat(acc?.amount)),
            0
          ) -
          parseFloat(mileStoneAmount || 0)
      );
    }
  }, [mileStoneAmount]);

  useEffect(() => {
    if (editedMilestoneItem?.item !== null) {
      setMileStoneTitle(editedMilestoneItem?.item?.title);
      setMileStoneDescription(editedMilestoneItem?.item?.description);
      setMileStoneAmount(editedMilestoneItem?.item?.amount);
      const newArray = [..._mileStone];
      newArray.splice(editedMilestoneItem?.index, 1);

      setFundsLeft(
        parseFloat(proposalAmount) -
          newArray?.reduce((val, acc) => (val += parseFloat(acc?.amount)), 0) -
          parseFloat(mileStoneAmount || 0)
      );
    }
  }, [editedMilestoneItem.item]);

  // handleAddMilestone
  const handleAddMilestone = () => {
    const milestone = {
      title: mileStoneTitle,
      description: mileStoneDescription,
      amount: mileStoneAmount,
    };

    for (let key in milestone)
      if (milestone[key] === "") return toast.warn(`${key} is required.`);

    if (mileStoneAmount == 0) return toast.warn("Amount can not be 0");

    // if we are updating or adding a milestone
    if (editedMilestoneItem?.item !== null) {
      const mileStoneCopy = [..._mileStone];
      mileStoneCopy[editedMilestoneItem.index] = milestone;
      setMileStone(mileStoneCopy);
    } else {
      setMileStone((p) => [...p, milestone]);
    }

    setMileStoneAmount("");
    setMileStoneDescription("");
    setMileStoneTitle("");
    handleClose();
  };

  return (
    <Modal
      show={mileStoneModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-backrop"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Proposal For{" "}
          {type == "freelancer" ? "Consultant" : "Client"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <label>MileStone Title :</label> <br />
          <input
            className="modal-inp mt-2"
            type={"text"}
            placeholder={"Enter MileStone Title"}
            value={mileStoneTitle}
            onChange={(e) => setMileStoneTitle(e.target.value)}
          />
        </p>
        <p>
          <label>Proposal Description :</label> <br />
          <textarea
            className="modal-inp mt-2 w-100"
            row={50}
            placeholder={"Enter MileStone Description"}
            value={mileStoneDescription}
            onChange={(e) => setMileStoneDescription(e.target.value)}
          />
        </p>
        <p
          style={{
            position: "relative",
          }}
        >
          <label>Proposal Amount :</label> <br />
          <span className="funds-di">
            Funds Left: {parseFloat(fundsLeft ?? proposalAmount).toFixed(2)}
          </span>
          <input
            className="modal-inp mt-2"
            type={"number"}
            placeholder={"Enter MileStone Amount"}
            value={mileStoneAmount}
            onChange={(e) => setMileStoneAmount(e.target.value)}
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="modal-btn" onClick={handleAddMilestone}>
          {editedMilestoneItem?.item !== null
            ? "Edit Milestone"
            : "Add MileStone"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
